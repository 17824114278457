import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertCaseBookingArchiveReceiverAddress, convertCaseBookingArchiveSenderAddress, convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageAccountBookingArchiveStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	serverFilter = null;
	archiveListPayload = null;

	// set financial year when get archive booking list
	setArchiveListPayload = (data) => {
		this.archiveListPayload = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertCaseBookingArchiveSenderAddress(data);
			data.receiverAddress = convertCaseBookingArchiveReceiverAddress(data);
		}
		this.viewValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setServerFilter = (data) => {
		this.serverFilter = data;
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		if (this.serverFilter) {
			this.agGrid.api.setFilterModel(this.serverFilter);
		}
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get archive a/c booking
	getList = (payload) => {
		payload.fy_id = this.archiveListPayload.fy_id;
		payload.center_id = this.archiveListPayload.center_id ?? null;
		return Axios.post("bookings/account/archive/list", payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);

				if (!filter_data.final_filter || Object.keys(filter_data.final_filter).length === 0) {
					this.agGrid.api.showNoRowsOverlay();
					params.successCallback([], 0);
					return;
				}

				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}

				let account_filter = this.agGrid.api.getFilterModel();
				this.setServerFilter(account_filter);
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay()
					} else {
						this.agGrid.api.hideOverlay()
					}
					params.successCallback(data.list.data, data.list.total)

					if (this.agGrid && this.agGrid.columnApi && data.total) {
						var allColumnIds = [];
						this.agGrid.columnApi.getAllColumns().forEach((column) => {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	ViewData = (payload) => {
		return Axios.post(`bookings/account/archive/details`, payload).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		}).catch(() => {
			return Promise.reject();
		});
	};
}

decorate(ManageAccountBookingArchiveStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	serverFilter: observable,
	setServerFilter: action,
	archiveListPayload: observable,
	setArchiveListPayload: action,
});
