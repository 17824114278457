import React, { useState } from "react";
import { Menu, Drawer, Button } from "antd";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import useStore from "../store";
import logo from "../images/corporateLogo.png";
import { Constant } from "../config/Constant";
import LogOutConfirmationModal from "../page/LogOutConfirmationModal";
import { LogoutIconNew } from "../config/IconsConfig";

const SidebarComponent = observer((props) => {
	const { AUTH } = useStore();
	const location = useLocation();
	const [openKeys, setOpenKeys] = useState([]);
	const [logoutModal, setLogoutModal] = useState(false)

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (latestOpenKey !== undefined && latestOpenKey) {
			setOpenKeys([latestOpenKey]);
		} else {
			setOpenKeys([keys]);
		}
	};

	const openLogoutModal = () => {
		setLogoutModal(true)
		AUTH.setLogOutValues(AUTH.user)
	};
	const closeLogoutModal = () => setLogoutModal(false)

	// iterate the menu items
	const AppMenu = (menu_item, open = []) => {
		return menu_item
			? menu_item.map((item) => {
				if (item.submenu) {
					return (
						<Menu.SubMenu key={item.name + item.id} title={item.name}>
							{AppMenu(item.submenu, [...open, item.name + item.id])}
						</Menu.SubMenu>
					);
				} else {
					if (
						location.pathname.includes(item.controller) &&
						openKeys.length <= 0
					) {
						if (open.length === 0) {
							open.push(item.controller);
						}
						setOpenKeys(open);
					}
					let isIntBooking = true;
					// International menu logic for location user based on state
					if (
						AUTH &&
						AUTH.user &&
						AUTH.user.level_id === Constant.LOCATION_USER &&
						AUTH.user?.int_state_ids
					) {
						let location = AUTH.user.centers.find(
							(item) => item.id === AUTH.user.default_location
						);
						const locationStateId = location?.from_pincode_details?.state_id;
						if (locationStateId) {
							const intStateIds = AUTH.user.int_state_ids.split(",");
							if (intStateIds.includes(locationStateId.toString())) {
								isIntBooking = false;
							}
						}
					}
					return (
						<Menu.Item
							key={item.controller}
							className={
								isIntBooking === false && item.group_id === 115
									? "international-hide-menu"
									: ""
							}
						>
							{isIntBooking === false && item.group_id === 115 ? (
								item.id === 1150001 && (
									<a
										className="custom-menu-item"
										href={Constant.INT_DOMAIN_URL}
										target="_blank"
										rel="noreferrer"
										onClick={() => close()}
									>
										<span>{item.name}</span>
									</a>
								)
							) : (
								<>
									<span>{item.name}</span>
									<Link to={item.controller} onClick={() => close()} />
								</>
							)}
						</Menu.Item>
					);
				}
			})
			: null;
	};

	const close = () => {
		props.setCollapsed(false);
	};

	return (
		<>
			<Drawer
				className="mainMenuDrawer"
				onClose={close}
				visible={props.collapsed}
				placement="left"
				closable={false}
			>
				<div className="menuWrapper">
					<Menu
						theme="light"
						mode="inline"
						openKeys={openKeys}
						onOpenChange={onOpenChange}
						defaultOpenKeys={openKeys}
						selectedKeys={location.pathname}
					>
						<div className="logo_corporate">
							<img
								src={AUTH.company ? AUTH.company.branding.logo : logo}
								alt=""
							/>
						</div>
						{AppMenu(AUTH.menu)}
						{/* Logout Button */}
						<Menu.Item key="logout" className="logout-menu-item">
							<Button type="primary" color="danger" onClick={openLogoutModal}>
								<span>Logout</span> <span style={{ height: "33px", width: "17px", fill: "#ffffff" }}><LogoutIconNew /></span>
							</Button>
						</Menu.Item>
					</Menu>
				</div>
			</Drawer>
			<LogOutConfirmationModal visible={logoutModal} close={closeLogoutModal} />
		</>
	);
});

export default SidebarComponent;
