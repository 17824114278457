export const Messages = {
    LOGIN: "Logged in successful",
    LOGOUT: "Successful",
    CREATED: "Successfully Created",
    UPDATED: "Successfully Updated",
    DELETED: "Successfully deleted",
    REQUIRED: "It cannot be blank",
    DUPLICATE_EMAIL: "Duplicate email",
    MIN_LENGTH_ERROR: "It must have at least #MIN# characters",
    MAX_LENGTH_ERROR: "Maximum length is #MAX# characters",
    MIN_LENGTH_DIGIT_ERROR: "It must have at least #MIN# digits",
    MAX_LENGTH_DIGIT_ERROR: "Maximum length is #MAX# digits",
    DUPLICATED: "It already exists",
    NOT_SELECTED: "It is not selected",
    PHONE_INVALID_CHARACTERS: "Invalid phone characters, allow 0-9, dash, space, slash and bracket",
    PINCODE_INVALID_CHARACTERS: "Invalid pin code characters, allow 0-9",
    MUST_BE_INTEGER: "It must be an integer",
    USER_REQUIRED: "Username cannot be blank",
    PASSWORD_INVALID_CHARACTERS: "Invalid password, allows number, alphabets and special characters",
    EMAIL: "Email sent successfully",
    USER_NAME_REQUIRED: "Username cannot be blank",
    NO_RECORD: "No Records Found",
    PASSWORD_NOT_MATCH: "Password and confirm password does not match",
    ATTR_UPDATED: "#ATTR# Successfully Updated",
    USERNAME_INVALID_CHARACTER: "Invalid username, allows A-Z, a-z, 0-9, underscore, dash and dot",
    INVALID_ATTR: "Invalid #ATTR# characters",
    INVALID_DIGIT_ATTR: "Invalid #ATTR# digits",
    SESSION_TIMEOUT_RANGE: "It must be 15 minutes to 1440 minutes",
    GEACE_PERIOD: "It must be 1 to 30 days",
    GEACE_PERIOD_RANGE: "It must 1 day to 30 days",
    IMAGE_VALIDATION: "Invalid image format it allows PNG, JPG and JPEG only",
    INVALID_IMAGE_FORMAT: "Invalid photo format",
    INVALID_AVTAR_IMAGE_FORMAT: "Invalid avtar format",
    PDF_IMAGE_VALIDATION: "Invalid attachment format it allows PDF, PNG, JPG and JPEG only",
    IMAGE_SIZE_VALIDATION: "Maximum size is #SIZE#",
    EMAIL_INVALID_CHARACTERS: "Invalid email, allows a-z, 0-9, underscore, dash, dot and at sign",
    INVALID_RANGE_ATTR: "Invalid characters, allow #RANGE#",
    PASSWORD_3_LENGTH: "Password length is 3 to 50 characters",
    PASSWORD_LENGTH: "Password length is 8 to 20 characters",
    MOBILE_LENGTH: "It must have 10 digits",
    MOBILE_INVALID_CHARACTERS: "Invalid mobile characters, allow 0-9",
    MOBILE_INVALID: "Invalid mobile number",
    USER_MIN_LENGTH: "Username must have at least #MIN# characters",
    GST_PAN_DOESNOT_MATCH: "PAN and GST PAN characters doesn't match",
    PAN_INVALID_CHARACTERS: "It must have 10 characters",
    GST_CERTIFICATE: "It cannot be blank",
    COPIED: "Copied",
    CUSTOMIZED_ZONE_RANGE: "It must be a number between 1 to 10",
    CASH_ODA_CHRG_RANGE: "It must be between 0 to 100",
    SMS_CHARGES: "It must be between 0 to 2",
    FAX_INVALID_CHARACTERS: "Invalid fax characters, allow 0-9, dash, space, slash and bracket",
    MAXIMUM_UPLOADS_INVALID_CHARACTERS: "Invalid characters",
    MINIMUM_UPLOADS: "Minimum upload is #MIN#",
    MAXIMUM_UPLOADS: "Maximum upload is #MAX#",
    COMPRESS_ERROR: "We cannot compress the actual file. Please upload another file",
    PINCODE_MAX_LENGTH: "Maximum #MAX# pin codes",
    PINCODE_INVALID: "Invalid pin code #PINCODE#, allow numbers between 0-9 and it must be 6 characters only",
    CUSTOMIZED_RC_PINCODE_RANGE: "It must be a number between 1 to 7000",
    CENTER_NOT_SELECTED: "Location is not selected",
    PRODUCTS_REQUIRED: "Need to select at least one product",
    PASSWORD_SAME_ERROR: "Password and New Password cannot be same",
    PASSWORD_MISMATCH_ERROR: "Password and Confirm Password does not match",
    PDF_VALIDATION: "Invalid format,  it allows PDF only",
    INVALID_PDF: "Invalid attachment format it allows PDF only",
    CODE_INVALID_CHARACTERS: "Invalid code characters",
    BAG_SERIES_INVALID_CHARACTERS: "Invalid bag series characters",
    HUB_PHONE_INVALID_CHARACTERS: "Invalid phone characters, allow 0-9, dash, space, slash and bracket",
    CENTER_PHONE_INVALID_CHARACTERS: " Invalid landline characters, allow 0-9, dash, space, slash and bracket",
    RATECARD_INVALID_RANGE: "Range between 0.01 to 9999.99, for e.g. 5.29",
    RATECARD_ZERO_INVALID_RANGE: "Range between 0.00 to 9999.99, for e.g. 5.29",
    RATECARD_ZERO_MINMAX_INVALID_RANGE: "Range between #MINTOMAX#, for e.g. 5.29",
    REP_PASSWORD_NOT_MATCH: "It should be match with password.",
    AADHAR_INVALID_CHARACTERS: "It must have 12 digits",
    DRIVING_INVALID_CHARACTERS: "It must have 16 characters",
    DUPLICATED_WEIGHT: "Weight cannot be duplicated",
    DATE_RANGE_INVALID: "It should be same or later than from date",
    TO_DATE_INVALID: "It should be same or later than from date",
    NEWS_ERROR: "It must have at least 5 characters",
    END_SLAB_REQUIRED: "Missing end slab",
    DUPLICATED_END_SLAB: "End slab cannot be duplicated",
    ATTR_INVALID: "Invalid #ATTR# characters, allow 0-9.",
    ATTR_NUMBER_INVALID: "Invalid #ATTR#, allow 0-9.",
    ATTR_RATE_INVALID: "Invalid rate characters, allow 0-100.",
    END_SLAB_RANGE: "Range between 0 to 99,99,999",
    FIXED_RATE_RANGE: "Range between 0 to 100, for e.g. 5.29",
    FIXED_AMT_UPTO: "It must be less than or equal to end slab amount",
    LESS_START_DATE: "It must be less than or equal to end date",
    GREATER_END_DATE: "It must be greater than or equal to start date",
    FUEl_CAPACITY: "It must be a number between 1 to 100",
    MFG_YEAR: "Invalid mfg year, allow digits only",
    LABEL_INVALID_CHARACTERS: "Invalid label characters, allow 0-9, A-Z, a-z, dash, space, dot and bracket",
    INSURANCE_RATE_INVALID_CHARACTERS: "Invalid characters, allow 0 to 100, eg: 6.25",
    INSURANCE_RATE_RANGE: "It must be between 0 to 100",
    SMS_CHARGES_RANGE: "It must be between 0 to 2",
    SMS_CHARGES_INVALID_CHARACTE: "Invalid characters, allow 0 to 2, eg: 0.45",
    WHATSAPP_INVALID_CHARACTERS: "Invalid whatsapp characters, allow 0-9",
    WHATSAPPE_INVALID: "Invalid whatsapp number",
    PAYMENT_MODE_REQUIRED: "It must be selected",
    FORM_DATE_FIRST: "Please select from date first",
    INVALID_SECRET_CODE: "Invalid secret code, allows number, alphabets and special characters",
    UIN_INVALID_CHARACTERS: "It must have 15 characters",
    START_DATE_FIRST: "Please select Start Date first",
    END_DATE_INVALID: "It should be greater than start date",
    ACK_DATE_INVALID: "Acknowledge date should be greater or equal to dispatch date",
    CREDIT_PERIOD_RANGE: "Maximum value is 365 days",
    COURIER_EXPENSE_RANGE: "It must be a number between 1 to 1,00,000",
    MAXIMUM_BUSINESS_RANGE: "It should be either greater than minimum business or less than or equal to 1,00,00,000",
    LATLNG_RANGE: "A value with six to twelve decimal places",
    LICENSE_NUMBER_INVALID_CHARACTERS: "Invalid license number characters, allow 0-9 and a-z, A-Z",
    REGISTRATION_NO_INVALID_ERROR: "Invalid Registration No, allow alphanumeric",
    MOBILE_NOT_MATCH: "Mobile and confirm Mobile does not match",
    EMAIL_NOT_MATCH: "Email and confirm email does not match",
    ATTR_INVALID_END_SLAB: "Invalid end slab characters, allow 0-9",
    REORDER_LEVEL: "It must be a number between 1 to 9,99,999",
    STICKER_RATE: "Rate must be in range 0 to 100",
    TOTAL_RATE: "It cannot be 0",
    MIN_QTY: "Qty must be in range 10 to 10,000",
    CREDITS: "Credits must be in range 0 to 100",
    CASH_SERIES: "Must be a valid integer in range 10000000000000 to 99999999999999",
    cb_series: "It must not be same as A/C Booking Series",
    ab_series: "It must not be same as Cash Booking Series",
    AVERAGE_BUSINESS_RATE_FOR_SPECIFIED_PERIOD: "It must be between 1 to 6",
    EXPRESS_COVER_RATE_BELOW_AVERAGE_BUSINESS: "It must be between 1 to 500",
    ONE_TO_HANDED_RATE_RANGE: "It must be between 1 to 100",
    QTY: "Invalid characters, allow 1 to 9,99,999",
    VRQTY: "Qty must be in range 1 to #MAX#",
    INVALID_FROM_BARCODE: "Invalid characters, allow 0-9",
    INVALID_FROM_BARCODE_TO_BARCODE: "It must be 13 digits",
    TO_BARCODE: "It must be greater than or equal to `From Barcode`",
    QTY_MIN: "It must have at least #MIN# qty",
    QTY_MAX: "Maximum value is #MAX#",
    QTY_MULTIPLY_ERROR: "It should be in multiplication of 10",
    COVER_ORDER_QTY_MULTIPLY_ERROR: "It should be in multiplication of 1",
    FIXDIGITINVALID: "It must be #DIGIT# digits",
    APPROVED_REFUND_RANGE_ERROR: "Valid range is 0 to #AMOUNT#",
    PRINT_RATE_INVALID_CHARACTERS: "Invalid characters, allow 0.00 to 100.00, eg: 6.25",
    INVALID_AREA_NAME: "It should be alphanumeric. Only numbers not allowed",
    BOOOKING_MIN_VAL_INS: "It must be between 1000 - 9,99,999",
    BOOOKING_ERROR_MIN_VAL_INS: "It must be valid between 1000-9,99,999",
    BOOKING_LOCK_MESSAGE: "Your booking has been locked due to #LOCK_NAME#. Kindly clear your #LOCK_NAME# or contact HO for unlock bookings",
    TRADERS_GST: "It must have #MIN# characters",
    TRADERS_PINCODE: "Invalid pin code, allows 0-9",
    TRADERS_PHONE1: "Invalid phone characters, allow 0-9, dash, space, slash and bracket",
    HUB_LANDLINE_INVALID_CHARACTERS: "Invalid landline characters, allow 0-9, dash, space, slash and bracket",
    TRADERS_WHATSAPP: "Invalid whatsapp characters, allow 0-9",
    INVALID_CREDIT_PERIOD_CHARACTERS: "Invalid credit period characters",
    STICKERS_RATES_NOT_AVALIBLE: "There is no rates available for location or hub.",
    INSTALLMENT_RANGE: "Must be an integer between 0 to 9,99,999",
    SALARY_RANGE_EROR: "It must be in range of 0 to 99,999",
    INSTALMENT_ERROR: "It must be between 0 to 9,99,999",
    WEB_INVALID_CHARACTERS: "Invalid web, allows a-z, 0-9, underscore, dash, dot and at sign",
    INVALID_MIN_MAX_RANGE: "It should be between #MINTOMAX#",
    RANGE_MIN_MAX: "It must be between #MIN# to #MAX#",
    CONSIGNMENT_ERR: "Consignment number is not available, please contact administrator",
    PACKETNUMBER_ERR: "Packet number is not available, please contact administrator",
    BAGNUMBER_ERR: "Bag number is not available, please contact administrator",
    INVALID_SR_NO: "Invalid #NAME# number",
    RATE_CARD_ERR: "Rate Card not found, please contact administrator",
    RATE_CARD_PRODUCT_ERR: "Rates are not available for this product, please contact administrator",
    RATE_CARD_FRO_ISSUE: "Please update rate card for this A/C Party, Because Franchisee Overhead has been changed",
    SWITCH_PRODUCT_ERR: "Product can't switch, please contact administrator",
    NON_SERVICEABLE_PINCODE_ERR: "It is non-servicable area or pincode.",
    INVALID_VIRTUAL_CONSIGNMENT_BARCODE: "It must have 11 digits virtual consignment number",
    VIRTUAL_CONSIGNMENT_BARCODE_ERROR: "You can't use virtual consignment number in regular booking",
    INVALID_EXPRESS_CONSIGNMENT_BARCODE: "It must have 11 digits",
    INVALID_CONSIGNMENT_BARCODE: "It must have 13 digits",
    RENT_AMOUNT_INVALID_CHARACTERS: "Invalid rent amount characters, allow 0-9",
    ALLOCATED_INVALID_FROM_BARCODE_TO_BARCODE: "It must be 8 digits",
    QTY_LESS_OR_EQUAL: "It should be less than or equal to Stock",
    BOTH_SELECT: "Please check all boxes if you want to proceed",
    INVALID_RATES_BY_LOCATION: "Invalid characters, allow 0.00 to 100.00, eg: 6.25",
    BY_LOCATION_HUB_EXPRESS: "Qty must be in range 5 to 10,000",
    TO_BARCODE_INVALID_ORDER_QUANTITY: "To barcode series must not exceed order quantity",
    TO_BARCODE_INVALID: "It must be greater than or equal to from barcode",
    FROM_BARCODE_INVALID: "It must be greater than or equal to from barcode",
    MODE_OF_TRANSPORTATION: "Mode of transportation is not selected",
    INVALID_NUMERIC: "It must be numeric",
    WEIGHT_INVALID: "Invalid characters, allow between 0 to 9,999 KG , eg: 6.25",
    WEIGHT_INVALID_RANGE: "It must be between 0 to 9,999 KG",
    INVALID_GST_STATE_CODE: "GST State code doesn't match with the selected state",
    GSTNO_SAME_AS_STATE: "GST Number cannot be same as HO's GST Number",
    GSTNO_SAME_AS_LOCATION: "GST Number cannot be same as Booking Location's GST Number",
    INVALID_NO_LENGTH_ERROR: "It must have #LENGTHNUMBER# characters, allows 0-9.",
    FLIGHT_NO_INVALID_ERROR: "Invalid Flight No, allow alphanumeric",
    VEHICLE_NO_INVALID_ERROR: "Invalid Vehicle No, allow alphanumeric",
    INVOICE_NO_INVALID_ERROR: "Invalid Invoice No, allow alphanumeric",
    CREDIT_NOTE_NO_INVALID_ERROR: "Invalid Credit Note No, allow alphanumeric",
    MINIMUM_BILLING: "Range between 0 to 10,00,000",
    MINIMUM_BILLING_AMOUNT: "Invalid minimum billing amount characters",
    INVALID_MIN_MAX_DIGITS: "It must have #MINMAX# digits",
    SHIPMENT_NO_NOT_ASSIGNED: "Found several shipments #CODE# for which the series is not assigned, so the process has skipped same entries",
    INVALID_LOCATION_FROM_PINCODE: "From Pin Code cannot be blank, Please contact HO or Franchisee",
    INVALID_CASH_BARCODE: "Invalid sticker code for Cash booking",
    INVALID_ACC_BARCODE: "Invalid sticker code for Account booking",
    BARCODE_VALUE_ERROR: "It must be higher than From Barcode",
    INVALID_BARCODE_RANGE: "Barcode series must be sub-set of the original series",
    LANDLINE_INVALID_CHARACTERS: "Invalid landline characters, allow 0-9, dash, space, slash and bracket",
    CONSIGNMENT_WRONG_SORTING: "It should be either 11 or 13 digits code",
    SHIPMENT_REQUIRED: "Shipment Number cannot be blank",
    NOTE_REQUIRED: "Note cannot be blank",
    START_OR_END_DATE_REQUIRED: "Either start date or end date is mandatory",
    SHIPMENT_LEN_ERROR: "Shipment must be  a 11 or 13 digit number",
    IATA_CODE: "It must have 3 character",
    INVALID_IATA_CODE: "Invalid iata code, allows a-z",
    BAG_TRACKING_DISABLED: "Access denied, bag tracking is disabled",
    CON_TRACKING_DISABLED: "Access denied, consignment tracking is disabled",
    PACKET_TRACKING_DISABLED: "Access denied, packet tracking is disabled",
    INVALID_TRACKING_NUMBER: "Invalid tracking number",
    INVALID_CON_NO_ID: "Invalid Con. Number reference",
    INVALID_ACCESS: "Location cannot #NAME# bag",
    VENDOR_CODE_INVALID_CHARACTERS: "Invalid vendor code characters, allow A-Z, a-z, and 0-9",
    STICKERS_RATES_NOT_AVALIBLE_PACKET_HUB: "There is no rates available for hub.",
    AUDIT_WEIGHT_MAX_ISSUE: "It must be less than #MAX#",
    ACT_WEIGHT_COMPARE: "Actual weight cannot be same or less than weight",
    OUTSCAN_LENGTH_ISSUE: "Cannot outscan as no entries found",
    BAG_LENGTH_ISSUE: "At least select one bag for finalize drive",
    INVALID_AMOUNT: "Invalid characters, allow 100 to 99,99,999",
    DEPOSITS_AMOUNT: "It must be between 100 to 99,99,999",
    INVALID_CONSIGNMENT: "Invalid consignment characters, allow 0-9",
    INVALID_DRS_NO: "Invalid drs no characters, allow 0-9",
    INVALID_WEIGHT: "Invalid characters, allow between 0 to 99,99,999 , eg: 6.25",
    INVALID_FLOAT_AMOUNT: "Invalid characters, allow between 1.00 to 99,99,999.00 , eg: 50.25",
    WEIGHT_GMS: "It must be between 50.00 gms to 99999.99 gms",
    WEIGHT_KGS: "It must be between 0.05 kgs to 99.99 kgs",
    FILE_FORMAT_VALIDATION: "Invalid format, Allowed formats are #FILE_FORMAT#",
    DATE_RANGE_ERROR: "Max range is #DAYS# days",
    CON_NO_EXPRESS_NOT_ALLOWED: "Packet does not contain express booking number",
    CITY_NOT_SELECTED: "City is not selected",
    HUB_NOT_SELECTED: "Hub is not selected",
    RESEND_MAIL_ERROR: "Please select at least one account party to resend mail",
    REQUIRED_ATTR: "Need to select at least one #ATTR#",
    FORM_TO_CON: "It cannot be blank as there is data in either from consignment or to consignment.",
    MAX_REQUIRED_ATTR: "Do not select more than #MAX# entry",
    MIN_LENGTH_NUMBER_ERROR: "It must have at least 1 number",
    MAX_REQUIRED_DIGIT: "Do not enter more than #MAX#",
    MIN_REQUIRED_DIGIT: "Do not enter less than #MIN#",
    INVALID_DAYS:"Invalid characters, allow 0 to 365",
    DAYS: "It must be between 0 to 365",
    POD_NOT_AVAILABLE: "There is No POD found",
    LESS_THAN_FROM_DAYS: "Cannot be greater than From back days",
    DATE_TIME_INVALID:"Time must be after an hour",
    INVALID_MIN_MAX_AMOUNT: "It must have #MINMAX# amount",
    BOX_MIN: "It must have at least #MIN# boxes",
    PARENT_CHILD_RANGE_CON_NO: "This #CON_NO# is already used either in child or parent booking.",
    INVALID_SWITCH_PRODUCT_ERR: "Do not switch product",
    BILL_GENERATED_ERROR:"It is not possible due to bill is generated.",
    PARENT_CHILD_TRANSFER_INVALID: "It can not be transferable because of parent child booking is not supportable in this account.",
    INVALID_RANGE_ATTR_FLOATVALUE: "Invalid characters, allow 0-9, for e.g. 5.29",
    LCK_MANUAL_TRACKING_FLAGS: "Out of the four locks, one lock is mandatory",
    LCK_MANUAL_ALLOW_SINGLE_LOCATION: "You can not select more than one location",
    INT_WEIGHT_KGS: "It must be between 0.50 kgs to 999.99 kgs",
    INT_ACT_WEIGHT_RANGE: "Range between 0.10 to 500, for e.g. 5.29",
    INT_DOC_TO_NONDOC : "For weight more than 2.00 kgs, change Shipment Type from DOC to NON-DOC.",
    INT_CHARGE_RANGE: "Range between 0 to 9,999",
    EXPENSE_AMOUNT: "It must be between 1 to 99,99,999",
    INVALID_EXPENSE__AMOUNT: "Invalid characters, allow 1 to 99,99,999",
    EWAY_REQUIRED: "Please enter E-way Number",
    INAVALID_SALARY_RANGE : "Invalid characters, allow 0 to 99,999",
    DISTURBEDPINCODE:"Delivery for this pincode is Disturbed, So Don't book any urgent shipment.",
    INT_WALLET_CREDIT_REACHED:"You don't have enough credit, Please recharge your wallet.",
    CON_NO_NOT_EXIT : "Consigment number doesn't exit",
    INT_RATE_TYPE_MIN: "It must have at least #MIN# Rate Types",
    INT_BOOKING_OTHER_CHAR_WITH_GST : "Invalid characters, allow between 1 to 99,99,999",
    AGENCY_BANK_TDS : "It must be a number between 1 to 100",
    AGENCY_BANK_DISCOUNT : "It must be a number between 0 to 100",
    INVALID_SPLIT_AMOUNT: "Invalid characters, allow between 0.01 to #MAX#, for e.g. 8.11" ,
}