import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageHOInvoicesStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	viewValues = null;
	dropdown_courier_vendor_list = null;
	summary_filter = null;
	paymentsDetailValues = null;
	selectAllBillPrinting = false;
	splitValues = null
	newInvoice = {}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to view
	setPaymentsDetailValues = (data) => {
		this.paymentsDetailValues = data;
	};

	// set form values to split
	setSplitValues = (data) => {
		this.splitValues = data;
	};

	setNewInvoice = (data) => {
		this.newInvoice = data
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	setCheckBillPrintingState = (data) => {
		this.selectAllBillPrinting = data
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
		// let pcode = localStorage.getItem("ho_inv_code");
		// if (pcode && pcode !== "") {
		// 	const filter = {'pcode': {  filterType: 'number', type :"contains", filter: pcode }}
		// 	this.agGrid.api.setFilterModel(filter);
		// 	localStorage.removeItem("ho_inv_code");
		// }
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to  customefilter
	setOverviewFilterValues = (data) => {
		return new Promise((resolve) => {
			this.summary_filter = data;
			if (this.agGrid) {
				this.setupGrid(this.agGrid);
			}
			resolve()
		});
	};

	generatePayload = (summary_filter) => {
		return summary_filter;
	}


	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var advance_filter = this.generatePayload(this.summary_filter);
				var payload = {
					advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/ho-activities/tax-invoice/list`, payload).then(({ data }) => {			
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// set form values to  customefilter
	setAdvanceFilterValues = (data = null) => {
		this.advance_filter = data;
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	//setAdvanceFilter
	setAdvanceFilter = () => {
		let setAdvanceFilter = {};
		return setAdvanceFilter;
	}

	// Call delete api
	DeleteHOInvoices = (formdata) => {
		return Axios.post(`/ho-activities/jobs/cancellation`, formdata)
			.then(({ data }) => {
				this.setupGrid(this.agGrid);
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call delete api
	DeleteTaxInvoices = (ID) => {
		return Axios.delete(`/ho-activities/tax-invoice/cancellation/${ID}`)
			.then(({ data }) => {
				this.setupGrid(this.agGrid);
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	// call api to get Payments Detail records
	PaymentsDetailData = (formdata) => {
		return Axios.post(
			`/invoice-get-payments/detail`, formdata
		).then(({ data }) => {
			this.setPaymentsDetailValues(data.detail)
			return data.detail;
		});
	};


	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`/ho-activities/ho-invoice/view/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};

	doGenerateBillsPdf = (data) => {
		return Axios.post(`/ho-activities/ho-invoice/print`, data)
			.then(({ data }) => {
				if (this.agGrid) {
					this.agGrid.api.deselectAll();
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	doExportDownloadDetail = (data) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		return Axios.post(`/ho-activities/ho-invoice/list/export`, { data, filter, sort })
			.then(({ data }) => {
				this.agGrid.api.deselectAll();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call Split api
	SplitData = (formdata) => {
		return Axios.post(`/ho-activities/ho-invoice/split`, formdata)
			.then(({ data }) => {
				this.setupGrid(this.agGrid);
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call add api
	GenerateTaxInvoice = (formdata) => {
		return Axios.post(`/ho-activities/tax-invoice/generate`, formdata)
			.then(({ data }) => {
				this.setupGrid(this.agGrid);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	onFirstDataRendered = () => {
		if (this.selectAll === true) {
			this.agGrid.api.forEachNode((node) => {
				this.selectCount++;
				return node.setSelected(!!node.data);
			});
		}
	};

	onSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstDataRendered()
		} else {
			this.agGrid.api.forEachNode((node) => {
				return node.setSelected(false);
			});
		}
	}

	onFirstBillPrintingDataRendered = () => {
		if (this.selectAllBillPrinting === true) {
			this.agGrid.api.forEachNode((node) => {
				this.selectCount++;
				return node.setSelected(!!node.data);
			});
		}
	};

	onBillPrintingSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstBillPrintingDataRendered()
		} else {
			this.agGrid.api.forEachNode((node) => {
				return node.setSelected(false);
			});
		}
	}


}

decorate(ManageHOInvoicesStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
	setAdvanceFilterValues: action,
	setOverviewFilterValues: observable,
	overview_filter: action,
	summary_filter: observable,	
	setPaymentsDetailValues: action,
	paymentsDetailValues: observable,
	setCheckBillPrintingState:action,
	selectAllBillPrinting:observable,
	splitValues : observable,
	setSplitValues : action,
	newInvoice : observable,
	setNewInvoice : action
});
