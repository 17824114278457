import React from "react";

const Blank = React.lazy(() =>
	import("../page/Blank")
);
const Unauthorized = React.lazy(() =>
	import("../page/commonpage/Unauthorized")
);
const Dashboard = React.lazy(() =>
	import("../page/Dashboard")
);
const ForgotPassword = React.lazy(() =>
	import("../page/ForgotPassword")
);
const Login = React.lazy(() =>
	import("../page/Login")
);
const ResetPassword = React.lazy(() =>
	import("../page/ResetPassword")
);
const Page404 = React.lazy(() =>
	import("../page/ErrorPages/Page404")
);
const LicenseExpired = React.lazy(() =>
	import("../page/ErrorPages/LicenseExpired")
);
const ChangePassword = React.lazy(() =>
	import("../page/ChangePassword")
);
const VerifyOtp = React.lazy(() =>
	import("../page/VerifyOtp")
);
const CaptchaVerifyOtp = React.lazy(() =>
	import("../page/CaptchaVerifyOtp")
);
const EmailVerify = React.lazy(() =>
	import("../page/EmailVerify")
);
const EmailClientVerify = React.lazy(() =>
	import("../page/EmailClientVerify")
);
const ManageCountry = React.lazy(() =>
	import("../page/module/administration/Country")
);
const ManageClientGroup = React.lazy(() =>
	import("../page/module/organization/ClientGroup")
)
const ManageContinents = React.lazy(() =>
	import("../page/module/administration/Continents")
);
const ManageStates = React.lazy(() =>
	import("../page/module/administration/State")
);
const ManageCity = React.lazy(() =>
	import("../page/module/administration/City")
);
const ManageBank = React.lazy(() =>
	import("../page/module/administration/Bank")
);
const ManageBankAccount = React.lazy(() =>
	import("../page/module/administration/BankAccounts")
);
const ManagePinCode = React.lazy(() =>
	import("../page/module/administration/PinCode")
);
const ManageRole = React.lazy(() =>
	import("../page/module/administration/ManageRole")
);
const ManageUsers = React.lazy(() =>
	import("../page/module/administration/Users")
);
const ManageUsersHub = React.lazy(() =>
	import("../page/module/administration/UsersHub")
);
const ManageUsersClient = React.lazy(() =>
	import("../page/module/administration/UserClient")
);
const CompanySettings = React.lazy(() =>
	import("../page/module/system/CompanySettings")
);
const ManageClientApiTracking = React.lazy(() =>
	import("../page/module/system/Config/ClientApiTracking")
);
const ManageSMS = React.lazy(() =>
	import("../page/module/administration/ManageSMS")
);
const ManageEmail = React.lazy(() =>
	import("../page/module/administration/Email")
);
const ManageZones = React.lazy(() =>
	import("../page/module/administration/Zones")
);
const ManageFinancialYear = React.lazy(() =>
	import("../page/module/system/FinancialYears")
);
const ManageApiClient = React.lazy(() =>
	import("../page/module/system/APIClients")
);
const ManagePincodeAreas = React.lazy(() =>
	import("../page/module/administration/PincodeAreas")
);
const ManageLevels = React.lazy(() =>
	import("../page/module/system/Levels")
);
const ManageDeliveryReturnReason = React.lazy(() =>
	import("../page/module/administration/DeliveryReturnReason")
);
const ManageDeliveryLocationTag = React.lazy(() =>
	import("../page/module/administration/DeliveryLocationTag")
);
const ManageDocumentTypes = React.lazy(() =>
	import("../page/module/administration/DocumentTypes")
);
const OwnerShipTypes = React.lazy(() =>
	import("../page/module/administration/OwnershipTypes")
);
const ParnerShipTypes = React.lazy(() =>
	import("../page/module/administration/PartnerShipTypes")
);
const Manageproducts = React.lazy(() =>
	import("../page/module/administration/ManageProducts")
);
const BillingTypes = React.lazy(() =>
	import("../page/module/administration/BillingTypes")
);
const UserSessions = React.lazy(() =>
	import("../page/module/system/UserSession")
);
const ActivityLog = React.lazy(() =>
	import("../page/module/system/ActivityLog")
);
const UserDevice = React.lazy(() =>
	import("../page/module/administration/UserDevices")
);
const ManagePincodeTag = React.lazy(() =>
	import("../page/module/administration/PincodeTags")
);
const ManageHeadOffice = React.lazy(() =>
	import("../page/module/organization/HeadOffice")
);
const ManageHubs = React.lazy(() =>
	import("../page/module/organization/Hubs")
);
const ManageServiceArea = React.lazy(() =>
	import("../page/module/business/ServiceArea")
);
const ManageUsersFranchisee = React.lazy(() =>
	import("../page/module/administration/UserFranchisee")
);
const ManageUsersCenter = React.lazy(() =>
	import("../page/module/administration/UserCenter")
);
const ManageRateCard = React.lazy(() =>
	import("../page/module/business/RateCard")
);
const ManageBankAccountHo = React.lazy(() =>
	import("../page/module/organization/BankAccountsHo/Index")
);
const ManageBankAccountLocation = React.lazy(() =>
	import("../page/module/organization/BankAccountsLocation/Index")
);
const ManageBankAccountAlias = React.lazy(() =>
	import("../page/module/organization/BankAlias/Index")
);
const ManageApplication = React.lazy(() =>
	import("../page/module/system/Application")
);
const ManageAppReleases = React.lazy(() =>
	import("../page/module/system/AppReleases")
);
const ManageFranchisee = React.lazy(() =>
	import("../page/module/organization/Frainchaisee")
);
const ManageTerminatedFranchisee = React.lazy(() =>
	import("../page/module/organization/TerminatedFranchisee")
);
const ManagePieceRate = React.lazy(() =>
	import("../page/module/business/PieceRate")
);
const ManagePaymentMode = React.lazy(() =>
	import("../page/module/administration/PaymentMode")
);
const ManageNews = React.lazy(() =>
	import("../page/module/communication/ManageNews")
);
const ManageCirculars = React.lazy(() =>
	import("../page/module/communication/ManageCirculars")
);

const ManageBookingMessage = React.lazy(() =>
	import("../page/module/communication/ManageBookingMessage/component/FilterComponent")
);
const ManageBookingMessageList = React.lazy(() =>
	import("../page/module/communication/ManageBookingMessage/component/ListComponet")
);
const ManageClientMessage = React.lazy(() =>
	import("../page/module/communication/ManageClientMessage/component/FilterComponent")
);
const ManageClientMessageList = React.lazy(() =>
	import("../page/module/communication/ManageClientMessage/component/ListComponet")
);
const ManageGreetings = React.lazy(() =>
	import("../page/module/communication/ManageGreetings")
);
const ManageGreetingsReceipts = React.lazy(() =>
	import("../page/module/communication/ManageGreetings/component/ReadReceiptsList")
);
const RateTypes = React.lazy(() =>
	import("../page/module/administration/RateTypes")
);
const ManageNEWSReadReceipts = React.lazy(() =>
	import("../page/module/communication/ManageNews/component/ReadReceiptsList")
);
const ManageNotes = React.lazy(() =>
	import("../page/module/communication/ManageNote")
);
const ManageIndustrySegment = React.lazy(() =>
	import("../page/module/administration/ManageIndustrySegment")
);
const NoteReadReceiptsList = React.lazy(() =>
	import("../page/module/communication/ManageNote/component/ReadReceiptsList")
);
const CircularsReadReceiptsList = React.lazy(() =>
	import("../page/module/communication/ManageCirculars/component/ReadReceiptsList")
);
const ManageSlabRate = React.lazy(() =>
	import("../page/module/business/SlabRate")
);
const ManageOverheadExceptionByHub = React.lazy(() =>
	import("../page/module/business/OverheadExceptionByHub")
);
const ManageOverheadExceptionByLocation = React.lazy(() =>
	import("../page/module/business/OverheadExceptionByLocation")
);
const ApplyOverheadByHub = React.lazy(() =>
	import("../page/module/business/ApplyOverheadByHub")
);
const ApplyOverheadByLocation = React.lazy(() =>
	import("../page/module/business/ApplyOverheadByLocation")
);
const ManagePopupMessages = React.lazy(() =>
	import("../page/module/communication/ManagePopupMessages")
);
const PopupMessagesReadReceiptsList = React.lazy(() =>
	import("../page/module/communication/ManagePopupMessages/component/ReadReceiptsList")
);
const ManageDrivers = React.lazy(() =>
	import("../page/module/administration/Drivers")
);
const ManageVehicles = React.lazy(() =>
	import("../page/module/administration/Vehicles")
);
const ManageLocations = React.lazy(() =>
	import("../page/module/organization/Center")
);
const ManageCashBookingPolicy = React.lazy(() =>
	import('../page/module/business/CashBookingPolicy')
);
const ManageLocationsGroups = React.lazy(() =>
	import('../page/module/organization/LocationGroups')
);
const ManageRateCashCard = React.lazy(() =>
	import('../page/module/business/RateCardCash')
);
const ManageRateCashCardArchived = React.lazy(() =>
	import('../page/module/business/RateCardCashArchived')
);
const ManageFuelPolicies = React.lazy(() =>
	import('../page/module/business/FuelPolicies')
);
const ManageAccountPartyQuotes = React.lazy(() =>
	import('../page/module/organization/AccountPartyQuotes')
);
const ManageAccountParty = React.lazy(() =>
	import('../page/module/organization/AccountParty')
);
const ManageAccountPartyApproval = React.lazy(() =>
	import('../page/module/organization/AccountPartyApproval')
);
const ManageLocationApproval = React.lazy(() =>
	import('../page/module/organization/LocationApproval')
);
const ManageFranchiseApproval = React.lazy(() =>
	import('../page/module/organization/FranchiseApproval')
);
const ManageCorporateQuotes = React.lazy(() =>
	import('../page/module/organization/CorporateQuotes')
);
const ManageRateAccountCard = React.lazy(() =>
	import('../page/module/business/RateCardAccount')
);
const ManageCorporates = React.lazy(() =>
	import('../page/module/organization/Corporates')
);
const ManageBookingPolicyAccount = React.lazy(() =>
	import('../page/module/business/BookingPolicyAccount')
);
const ManageRateCorporatesCard = React.lazy(() =>
	import('../page/module/business/RateCardCorporates')
);
const ManageBookingPolicyCorporate = React.lazy(() =>
	import('../page/module/business/BookingPolicyCorporate')
);
const ManageStock = React.lazy(() =>
	import('../page/module/Stickers/Stock')
);
const ManageStockTransaction = React.lazy(() =>
	import('../page/module/Stickers/StockTransaction')
);
const ManageStickerRatesByHub = React.lazy(() =>
	import('../page/module/Stickers/StickerRatesByHub')
);
const ManageStickerRatesByLocation = React.lazy(() =>
	import('../page/module/Stickers/StickerRatesByLocation')
);
const ManageAllocatedSticker = React.lazy(() =>
	import('../page/module/Stickers/AllocatedSticker')
);
const ManageStickerOrder = React.lazy(() =>
	import('../page/module/Stickers/StickerOrder')
);
const ManageTraders = React.lazy(() =>
	import('../page/module/organization/Traders')
);
const ManageDeliveryBoyz = React.lazy(() =>
	import('../page/module/administration/DeliveryBoy')
);
const ManageCashBookings = React.lazy(() =>
	import('../page/module/Booking/CashBooking')
);
const ManageListCashBookings = React.lazy(() =>
	import('../page/module/Booking/CashBooking/component/ListComponent')
);
const ManageArchiveListCashBookings = React.lazy(() =>
	import('../page/module/Booking/CashBooking/component/Archive/ListComponent')
);
const ManageAddCashBookings = React.lazy(() =>
	import('../page/module/Booking/CashBooking/component/AddComponent')
);
const ManageAddAccountBookings = React.lazy(() =>
	import('../page/module/Booking/AccountBooking/component/AddComponent')
);
const ManageBulkAddCashBookings = React.lazy(() =>
	import('../page/module/Booking/CashBooking/component/BulkAddComponent')
);
const ManageEditCashBookings = React.lazy(() =>
	import('../page/module/Booking/CashBooking/component/EditComponent')
);
const ManageEditAccountBooking = React.lazy(() =>
	import('../page/module/Booking/AccountBooking/component/EditComponent')
);
const ManageComplaint = React.lazy(() =>
	import('../page/module/Booking/ManagComplaints')
)
const ManageExpressCoversOrder = React.lazy(() =>
	import('../page/module/Stickers/ExpressCoversOrder')
);
const ManageBagStickerOrder = React.lazy(() =>
	import('../page/module/Stickers/BagStickerOrder')
);
const ManageScrollingMessages = React.lazy(() =>
	import('../page/module/communication/ManageScrollingMessages')
);
const ManageExpressStickerOrder = React.lazy(() =>
	import('../page/module/Stickers/ExpressStickerOrder')
);
const ManagePacketStickerOrder = React.lazy(() =>
	import('../page/module/Stickers/ExpressStickerOrder/packetIndex')
);
const ManageAccountBooking = React.lazy(() =>
	import('../page/module/Booking/AccountBooking')
);
const ManageListAccountBookings = React.lazy(() =>
	import('../page/module/Booking/AccountBooking/component/ListComponent')
);
const ManageArchiveListAccountBookings = React.lazy(() =>
	import('../page/module/Booking/AccountBooking/component/Archive/ListComponent')
);
const ManageCoLoaders = React.lazy(() =>
	import('../page/module/administration/CoLoaders')
);
const ManagePacketEntry = React.lazy(() =>
	import('../page/module/Booking/PacketEntry')
);
const ManageBagEntry = React.lazy(() =>
	import('../page/module/Booking/BagEntry')
);
const ManageDrsArea = React.lazy(() =>
	import('../page/module/administration/DrsArea')
);
const ManageDrsWeb = React.lazy(() =>
	import('../page/module/Booking/DrsWeb')
);
const ManageAddDrsWeb = React.lazy(() =>
	import('../page/module/Booking/DrsWeb/component/AddComponent')
);
const ManageEditDrsBookings = React.lazy(() =>
	import('../page/module/Booking/DrsWeb/component/EditComponent')
);
const ManageOutwardEntry = React.lazy(() =>
	import('../page/module/Booking/OutwardEntry')
);
const ManageInscanClientBookingAPI = React.lazy(() =>
	import('../page/module/Booking/InscanClientBookingAPI')
);
const ManageInwardEntry = React.lazy(() =>
	import('../page/module/Booking/InwardEntry')
);
const ManageStickerVendors = React.lazy(() =>
	import('../page/module/administration/StickerVendors')
);
const ManageReturnToOriginEntry = React.lazy(() =>
	import('../page/module/Booking/ReturnToOriginEntry')
);
const ManageReturnToOrigin = React.lazy(() =>
	import('../page/module/Booking/ManageReturnToOrigin/Index')
);
const ManageNonServiceableEntry = React.lazy(() =>
	import('../page/module/Booking/NonServiceableEntry')
);
const ManageWrongSorting = React.lazy(() =>
	import('../page/module/Booking/WrongSorting/Index')
);
const wrongSortingForMe = React.lazy(() =>
	import('../page/module/Booking/WrongSortingForMe/Index')
);
const ManageLostShipment = React.lazy(() =>
	import('../page/module/Booking/LostShipment/Index')
);
const LostShimentForMe = React.lazy(() =>
	import('../page/module/Booking/LostShipmentForMe/Index')
);
const ManageStaff = React.lazy(() =>
	import('../page/module/administration/ManageStaff')
);
const MobileConsignmetnTracking = React.lazy(() =>
	import('../page/MobileConsignmentTracking')
);
const ManageStickerOrderPacketHub = React.lazy(() =>
	import('../page/module/Stickers/StickerOrderPacketHub')
);
const ManageAuditWeight = React.lazy(() =>
	import('../page/module/Booking/AuditWeight')
);
const ManageTrainingVideos = React.lazy(() =>
	import('../page/module/Help/TrainingVideos')
);
const ManageDownloads = React.lazy(() =>
	import('../page/module/Help/Downloads')
);
const ManageRateAccountDraftCard = React.lazy(() =>
	import('../page/module/business/DraftRateCard/indexAccount')
);
const ManageRateCashDraftCard = React.lazy(() =>
	import('../page/module/business/DraftRateCard/indexCash')
);
const ManageRateCorporateDraftCard = React.lazy(() =>
	import('../page/module/business/DraftRateCard/indexCorporate')
);
const ManageDeposits = React.lazy(() =>
	import('../page/module/Account/Deposits')
);
const ManageInscanForLocation = React.lazy(() =>
	import('../page/module/Booking/InscanForLocation')
);
const ManageCourierVendors = React.lazy(() =>
	import('../page/module/administration/CourierVendors')
);
const ManageSatellitePoints = React.lazy(() =>
	import('../page/module/administration/SatellitePoints')
);
const ManageCopyAccountPartyQuotation = React.lazy(() =>
	import('../page/module/business/CopyAccountPartyQuotation')
);
const ManageBagTagStickerOrders = React.lazy(() =>
	import("../page/module/Stickers/BagTagStickerOrders")
);
const ManageAccountEmailPool = React.lazy(() =>
	import('../page/module/communication/ManageAccountEmailPool')
);
const ManageContract = React.lazy(() =>
	import('../page/module/business/ManageContract')
)
const ManageContractList = React.lazy(() =>
	import('../page/module/business/ManageContract/component/ListComponent')
)
const ManageLocationSearch = React.lazy(() =>
	import('../page/module/Utilities/LocationSearch')
)
const ManageForwardBooking = React.lazy(() =>
	import('../page/module/Booking/ForwardBooking')
)
const ManageAddForwardBooking = React.lazy(() =>
	import('../page/module/Booking/ForwardBooking/Component/AddComponent')
);
const ManageTransferEntry = React.lazy(() =>
	import('../page/module/Booking/TransferEntry/component/AddComponent')
);
const ManageVirtualOrder = React.lazy(() =>
	import('../page/module/Stickers/VirtualOrder')
)
const ManageAccountBookingSendEmail = React.lazy(() =>
	import('../page/module/Utilities/AccountBookingSendEmail')
)
const ManageOnDemandReport = React.lazy(() =>
	import('../page/module/Report/OnDemandReport')
);
const ManageBilling = React.lazy(() =>
	import('../page/module/MonthlyActivities/Billing')
);
const ManagebillingGenerate = React.lazy(() =>
	import('../page/module/MonthlyActivities/Billing/Component/OverviewListComponent')
);
const ManagebillingSearch = React.lazy(() =>
	import('../page/module/MonthlyActivities/Billing/Component/ListComponent')
);
const ManagebillingCancelled = React.lazy(() =>
	import('../page/module/MonthlyActivities/Billing/Component/CancelledList')
);
const ManagebillingCancelledHistory = React.lazy(() =>
	import('../page/module/MonthlyActivities/Billing/Component/CancelledHistoryList')
);
const ManageUpdateBookingRate = React.lazy(() =>
	import('../page/module/Booking/UpdateBookingRate/component/AddComponent')
);
const ManageUpdatePieceRatePerQuotation = React.lazy(() =>
	import('../page/module/Booking/UpdatePieceRatePerQuotation/component/AddComponent')
);
const ManageEstimation = React.lazy(() =>
	import('../page/module/MonthlyActivities/Estimation')
);
const ManageEstimationGenerate = React.lazy(() =>
	import('../page/module/MonthlyActivities/Estimation/Component/OverviewListComponent')
);
const ManageEstimationSearch = React.lazy(() =>
	import('../page/module/MonthlyActivities/Estimation/Component/ListComponent')
);
const ManageEstimationCancelled = React.lazy(() =>
	import('../page/module/MonthlyActivities/Estimation/Component/CancelledList')
);
const ManagePincodeSearch = React.lazy(() =>
	import('../page/module/Utilities/PincodeSearch')
);
const ManageHubSearch = React.lazy(() =>
	import('../page/module/Utilities/HubSearch')
);
const ManageCorporatesBilling = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesBilling')
);
const ManageCorporateInvoiceJob = React.lazy(() =>
	import('../page/module/HOActivities/CorporateInvoiceJob')
);
const ManageCorporateInvoiceJobGenerate = React.lazy(() =>
	import('../page/module/HOActivities/CorporateInvoiceJob/component/OverviewListComponent')
);
const ManageCorporateInvoiceJobBills = React.lazy(() =>
	import('../page/module/HOActivities/CorporateInvoiceJob/component/ListComponent.js')
);
const ManageCorporatesBillingGenerate = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesBilling/Component/OverviewListComponent')
);
const ManageCorporatesBillingSearch = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesBilling/Component/ListComponent')
);
const ManageCorporatesBillingCancelled = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesBilling/Component/CancelledList')
);
const ManageHOBilling = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling')
)
const ManageHOBillingCreateJobBYHub = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/JobBYHubListComponent')
)
const ManageHOBillingJobBYLocationGroup = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/JobBYLocationGroup')
)
const ManageHOBillingjobs = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/HOBillingJobsListComponent')
)
const ManageHOBillingRequests = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/HOBillingRequestsListComponent')
)
const ManageHOBillingNotRequests = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/HOBillingNotRequestsListComponent')
)
const ManageHOBillingDueDetail = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/HOBillingDueDetailListComponent')
)
const ManageHOPendingBilling = React.lazy(() =>
	import('../page/module/HOActivities/HOBilling/Component/HOPendingBillingListComponent')
)
const ManageHOBillingInvoices = React.lazy(() =>
	import('../page/module/HOActivities/HOInvoices')
)
const ManagePerformaInvoices = React.lazy(() =>
	import('../page/module/HOActivities/HOInvoices/Component/PerformaInvoicesListComponent')
)
const ManagePendingInvoices = React.lazy(() =>
	import('../page/module/HOActivities/HOInvoices/Component/PendingInvoicesListComponent')
)
const ManageTaxInvoices = React.lazy(() =>
	import('../page/module/HOActivities/HOInvoices/Component/TaxInvoicesListComponent')
)
const ManageInvoicesWithoutGST = React.lazy(() =>
	import('../page/module/HOActivities/HOInvoices/Component/InvoicesWithoutGSTListComponent')
)
const ManageHoEditBooking = React.lazy(() =>
	import('../page/module/Booking/BookingEditHo/component/AddComponent')
)
const IncomingSearchFilter = React.lazy(() =>
	import('../page/module/Booking/IncomingSearch/Component/FilterComponent')
)
const IncomingSearchSummary = React.lazy(() =>
	import('../page/module/Booking/IncomingSearch/Component/SummaryComponent')
)
const OutgoingSearchFilter = React.lazy(() =>
	import('../page/module/Booking/OutgoingSearch/Component/FilterComponent')
)
const OutgoingSearchSummary = React.lazy(() =>
	import('../page/module/Booking/OutgoingSearch/Component/SummaryComponent')
)
const ManageCorporatesInvoices = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesInvoices')
)
const ManageBillingInvoices = React.lazy(() =>
	import('../page/module/MonthlyActivities/BillingInvoices')
)
const ManageEstimationInvoices = React.lazy(() =>
	import('../page/module/MonthlyActivities/EstimationInvoices')
)
const WeightMachine = React.lazy(() =>
	import("../page/module/administration/WeightMachine")
);
const ManageInvoicesPayment = React.lazy(() =>
	import('../page/module/MonthlyActivities/ClientPayments/InvoicesPayment')
)
const ManageEstimationPayment = React.lazy(() =>
	import('../page/module/MonthlyActivities/ClientPayments/EstimationPayment')
)
const ManageNewInvoicesPayment = React.lazy(() =>
	import('../page/module/MonthlyActivities/ClientPayments/Component/AddPaymentComponent')
)
const ManagePaymentHistory = React.lazy(() =>
	import('../page/module/MonthlyActivities/ClientPayments/Component/HistoryListComponent')
)
const ManagePaymentOutstanding = React.lazy(() =>
	import('../page/module/MonthlyActivities/ClientPayments/Component/OutstandingListComponent')
)
const ManageConfigPendingDrs = React.lazy(() =>
	import("../page/module/Locks/ConfigPendingDRS")
);
const ManageConfigPendingDrsByLocation = React.lazy(() =>
	import("../page/module/Locks/ConfigPendingDRSByLocation")
);
const ManageConfigMissingConNo = React.lazy(() =>
	import("../page/module/Locks/ConfigMissingConNo")
);
const ManageConfigMissingConNoByLocation = React.lazy(() =>
	import("../page/module/Locks/ConfigMissingConNoByLocation")
);
const ManageConfigZeroDrs = React.lazy(() =>
	import("../page/module/Locks/ConfigZeroDRS")
);
const ManageConfigManualLock = React.lazy(() =>
	import("../page/module/Locks/ConfigManualLock")
);
const ManageBookingByPincode = React.lazy(() =>
	import("../page/module/Locks/BookingByPincode")
);
const ManageBookingLock = React.lazy(() =>
	import("../page/module/Locks/Bookings")
);
const ManageTrackingLock = React.lazy(() =>
	import("../page/module/Locks/Tracking")
);
const ManageCorporatePayment = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPayments')
)
const ManageCorporateNewPayment = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPayments/Component/AddPaymentComponent')
)
const ManageCorporatePaymentHistory = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPayments/Component/HistoryListComponent')
)
const ManageCorporatePaymentOutstanding = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPayments/Component/OutstandingListComponent')
)
const ManageCorporatePendingSettlement = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPayments/Component/PendingSettlementListComponent')
)
const ManageHOPayment = React.lazy(() =>
	import('../page/module/HOActivities/HOPayments')
)
const ManageHONewInvoicesPayment = React.lazy(() =>
	import('../page/module/HOActivities/HOPayments/Component/AddPaymentComponent')
)
const ManageHOPaymentHistory = React.lazy(() =>
	import('../page/module/HOActivities/HOPayments/Component/HistoryListComponent')
)
const ManageHOPaymentOutstanding = React.lazy(() =>
	import('../page/module/HOActivities/HOPayments/Component/OutstandingListComponent')
)
const ManageHOSettlement = React.lazy(() =>
	import('../page/module/HOActivities/HOSettlement')
)
const ManageHOSettlementHistory = React.lazy(() =>
	import('../page/module/HOActivities/HOSettlement/Component/HistoryListComponent')
)
const ManageHONewSettlementPayment = React.lazy(() =>
	import('../page/module/HOActivities/HOSettlement/Component/AddPaymentComponent')
)
const ManageEwayTransit = React.lazy(() =>
	import("../page/module/Booking/EwayTransit")
);
const ManageEwayFirstMile = React.lazy(() =>
	import("../page/module/Booking/EwayFirstMile")
);
const ManageEwayOutScanPending = React.lazy(() =>
	import("../page/module/Booking/EwayOutscanPending/component/FilterComponent")
);
const ManageEwayOutScanPendingSummary = React.lazy(() =>
	import("../page/module/Booking/EwayOutscanPending/component/SummaryComponent")
);
const ManageReverseBillingInvoices = React.lazy(() =>
	import('../page/module/HOActivities/ReverseInvoices')
)
const ManageReversePerformaInvoices = React.lazy(() =>
	import('../page/module/HOActivities/ReverseInvoices/Component/PerformaInvoicesListComponent')
)
const ManageReversePendingInvoices = React.lazy(() =>
	import('../page/module/HOActivities/ReverseInvoices/Component/PendingInvoicesListComponent')
)
const ManageReverseTaxInvoices = React.lazy(() =>
	import('../page/module/HOActivities/ReverseInvoices/Component/TaxInvoicesListComponent')
)
const ManageReverseInvoicesWithoutGST = React.lazy(() =>
	import('../page/module/HOActivities/ReverseInvoices/Component/InvoicesWithoutGSTListComponent')
)
const ManageReversePayment = React.lazy(() =>
	import('../page/module/HOActivities/ReversePayments')
)
const ManageReverseNewInvoicesPayment = React.lazy(() =>
	import('../page/module/HOActivities/ReversePayments/Component/AddPaymentComponent')
)
const ManageReversePaymentHistory = React.lazy(() =>
	import('../page/module/HOActivities/ReversePayments/Component/HistoryListComponent')
)
const ManageReversePaymentOutstanding = React.lazy(() =>
	import('../page/module/HOActivities/ReversePayments/Component/OutstandingListComponent')
)
const Circulars = React.lazy(() =>
	import('../page/module/Utilities/Circulars')
)
const AccountPartySearch = React.lazy(() =>
	import('../page/module/Utilities/AccountPartySearch')
)
const ManageHoEditForwardBooking = React.lazy(() =>
	import('../page/module/Booking/ForwardBookingEditHo/component/AddComponent')
)
const ManageICICIDataSync = React.lazy(() =>
	import("../page/module/Booking/ICICIDataSync")
);
const ManageFrainchaiseeEnquiry = React.lazy(() =>
	import("../page/module/organization/FrainchaiseeEnquiry")
);
const PendingBillsSearchFilter = React.lazy(() =>
	import('../page/module/MonthlyActivities/PendingBills/Component/FilterComponent.js')
)
const PendingBillsSearchDetail = React.lazy(() =>
	import('../page/module/MonthlyActivities/PendingBills/Component/DetailComponent.js')
)
const PendingBillsSearchSummary = React.lazy(() =>
	import('../page/module/MonthlyActivities/PendingBills/Component/SummaryComponent.js')
)
const CorporatesPendingBillsFilter = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPendingBills/Component/FilterComponent.js')
)
const CorporatesPendingBillsSummary = React.lazy(() =>
	import('../page/module/HOActivities/CorporatesPendingBills/Component/SummaryComponent.js')
)
const SoftwareCharge = React.lazy(() =>
	import('../page/module/HOActivities/SoftwareCharge')
)
const BookingEditHoApprove = React.lazy(() =>
	import('../page/module/Booking/BookingEditHoApprove')
)
const DisturbPincodes = React.lazy(() =>
	import('../page/module/administration/DisturbPincodes')
)
const ManageEwayUpdate = React.lazy(() =>
	import('../page/module/Eway/EwayUpdate')
)
const ManageProcessList = React.lazy(() =>
	import('../page/module/system/ProcessList')
)
const ManageStaffAttendance = React.lazy(() =>
	import('../page/module/Booking/StaffAttendance')
)
const ManageSalaryProcessing = React.lazy(() =>
	import('../page/module/Booking/SalaryProcessing')
)
const ManageGenerateAccountPartyLabel = React.lazy(() =>
	import('../page/module/MonthlyActivities/GenerateAccountPartyLabel')
)
const ManageAdvancedPayment = React.lazy(() =>
	import('../page/module/organization/AdvancedPayment')
)
const ManageAutoHOSettlement = React.lazy(() =>
	import('../page/module/HOActivities/AutoHOSettlement')
)
const ManageAuditShipment = React.lazy(() =>
	import('../page/module/Booking/AuditShipment')
)

/**
 * Please set report URL after this
 */
const NewClientReportFilter = React.lazy(() =>
	import("../page/module/Report/NewClientReport/Component/FilterComponent.js")
)
const NewClientReportDetails = React.lazy(() =>
	import('../page/module/Report/NewClientReport/Component/Details.js')
)
const ManageClientReportFilter = React.lazy(() =>
	import('../page/module/Report/ClientReport/Component/FilterComponent.js')
)
const ManageClientReportDetails = React.lazy(() =>
	import('../page/module/Report/ClientReport/Component/Details')
)
const ManageLocationCreationInformationFilter = React.lazy(() =>
	import("../page/module/Report/LocationCreationInformation/component/FilterComponent")
)
const ManageLocationCreationInformationListReport = React.lazy(() =>
	import("../page/module/Report/LocationCreationInformation/component/ListComponent")
)
const ManageInscanReport = React.lazy(() =>
	import("../page/module/Report/DailyActivities/InscanReport/component/FilterComponent.js")
)
const ManageInscanDetailsReport = React.lazy(() =>
	import("../page/module/Report/DailyActivities/InscanReport/component/DetailsComponent.js")
)
const ManageInscanSummaryReport = React.lazy(() =>
	import("../page/module/Report/DailyActivities/InscanReport/component/Summary.js")
)
const ManageInsurance = React.lazy(() =>
	import("../page/module/Report/DailyActivities/Insurance/Component/FilterComponent.js")
)
const ManageInsuranceDetails = React.lazy(() =>
	import("../page/module/Report/DailyActivities/Insurance/Component/Details.js")
)
const ManageInsuranceSummary = React.lazy(() =>
	import("../page/module/Report/DailyActivities/Insurance/Component/SummaryDetails.js")
)
const ManageOutwardManifestDetails = React.lazy(() =>
	import("../page/module/Report/OutwardManifest/component/DetailsComponent")
)
const ManageOutwardManifestFilter = React.lazy(() =>
	import("../page/module/Report/OutwardManifest/component/FilterComponent")
)
const ManageClientBooking = React.lazy(() =>
	import("../page/module/Report/ClientBooking/component/FilterComponent")
)
const ManageClientBookingDetails = React.lazy(() =>
	import("../page/module/Report/ClientBooking/component/BookingDetails")
)
const ManageForwardingBookingDetails = React.lazy(() =>
	import("../page/module/Report/ForwardingBooking/Component/ForwardingBookingDetails")
)
const ManageForwardingBookingSummary = React.lazy(() =>
	import("../page/module/Report/ForwardingBooking/Component/ForwardingBookingSummary")
)
const ManageForwordingBookingFilter = React.lazy(() =>
	import('../page/module/Report/ForwardingBooking/Component/FilterComponent')
)
const ManageBookingTypeFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/BookingType/Component/FilterComponent.js')
)
const ManageBookingTypeDetails = React.lazy(() =>
	import('../page/module/Report/DailyActivities/BookingType/Component/Details.js')
)
const ManageBookingTypeSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/BookingType/Component/Summary.js')
)
const ManageBookingTypeBasicSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/BookingType/Component/BasicSummary.js')
)
const ManageCityWiseBookingFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/CityWiseBooking/Component/FilterComponent.js')
)
const ManageCityWiseBookingSum = React.lazy(() =>
	import('../page/module/Report/DailyActivities/CityWiseBooking/Component/Summary.js')
)
const ManageCityWiseBookingDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/CityWiseBooking/Component/Detail')
)
const ManageLocationCollectionFilter = React.lazy(() =>
	import('../page/module/Report/LocationCollection/Component/FilterComponent')
)
const ManageLocationCollectionDetails = React.lazy(() =>
	import('../page/module/Report/LocationCollection/Component/LocationDetails')
)
const ManageLocationCollectionSummary = React.lazy(() =>
	import('../page/module/Report/LocationCollection/Component/SummaryDetails')
)
const ManageUnregisteredPacketFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnRegisteredPacket/component/FilterComponent.js')
)
const ManageUnregisteredPacketSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnRegisteredPacket/component/SummaryComponent.js')
)
const ManageUnregisteredPacketDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnRegisteredPacket/component/DetailComponent.js')
)
const ManageUnregisteredBagFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnRegisteredBag/component/FilterComponent.js')
)
const ManageUnregisteredBagSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnRegisteredBag/component/SummaryComponent.js')
)
const ManageUnregisteredBagDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnRegisteredBag/component/DetailComponent.js')
)
const ManageDailyBookingsFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/DailyBookings/component/FilterComponent.js')
)
const ManageDailyBookingsSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/DailyBookings/component/SummaryComponent.js')
)
const ManageDailyBookingsDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/DailyBookings/component/DetailComponent.js')
)
const ManageUnregisteredBookingsFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnregisteredBookings/component/FilterComponent.js')
)
const ManageUnregisteredBookingsSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnregisteredBookings/component/SummaryComponent.js')
)
const ManageUnregisteredBookingsDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/UnregisteredBookings/component/DetailComponent.js')
)
const ManageInscanAndOutscanActivitiesFilter = React.lazy(() =>
	import("../page/module/Report/DailyActivities/InscanAndOutscanActivities/Component/FilterComponent.js")
)
const SummaryByHubLocation = React.lazy(() =>
	import("../page/module/Report/DailyActivities/InscanAndOutscanActivities/Component/SummaryByHubLocation.js")
)
const SummaryByDate = React.lazy(() =>
	import("../page/module/Report/DailyActivities/InscanAndOutscanActivities/Component/SummaryByDate.js")
)
const ManageOutscanvsMissingInscanFilter = React.lazy(() =>
	import("../page/module/Report/DailyActivities/OutscanvsMissingInscan/Component/FilterComponent.js")
)
const ManageOutscanvsMissingInscanSummary = React.lazy(() =>
	import("../page/module/Report/DailyActivities/OutscanvsMissingInscan/Component/SummaryComponent.js")
)
const ManageOutscanvsMissingInscanDetail = React.lazy(() =>
	import("../page/module/Report/DailyActivities/OutscanvsMissingInscan/Component/DetailComponent.js")
)
const ManageHubIncomingLoadFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/HubIncomingLoad/component/FilterComponent.js')
)
const ManageHubIncomingLoadSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/HubIncomingLoad/component/SummaryComponent.js')
)
const ManageLocationIncomingLoadFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/LocationIncomingLoad/component/FilterComponent.js')
)
const ManageLocationIncomingLoadSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/LocationIncomingLoad/component/SummaryComponent.js')
)
const ManageHubPendingLoadFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/HubPendingLoad/component/FilterComponent.js')
)
const ManageHubPendingLoadSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/HubPendingLoad/component/SummaryComponent.js')
)
const ManageDrsPendingLoadFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/DrsPendingLoad/component/FilterComponent')
)
const ManageDrsPendingLoadSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/DrsPendingLoad/component/SummaryComponent')
)
const ManageDrsPendingLoadShortSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/DrsPendingLoad/component/ShortSummaryComponent')
)
const ManageBookingPendingLoadFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/BookingPendingLoad/component/FilterComponent.js')
)
const ManageBookingPendingLoadSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/BookingPendingLoad/component/SummaryComponent.js')
)
const ManageFranchiseeLogin = React.lazy(() =>
	import('../page/module/Report/FranchiseeLogin/component/ListComponent')
)
const ManageLocationLogin = React.lazy(() =>
	import('../page/module/Report/LocationLogin/component/ListComponent.js')
)
const ManagelocationServiceAreaLogin = React.lazy(() =>
	import('../page/module/Report/LocationServicePincodeAreaDetails/component/ListComponent')
)
const ManageHubWiseInscanOutscanFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/HubWiseInscanOutscan/component/FilterComponent.js')
)
const ManageHubWiseInscanOutscanSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/HubWiseInscanOutscan/component/SummaryComponent.js')
)
const ManageBookingDeliveryFilter = React.lazy(() =>
	import('../page/module/Report/BookingDelivery/component/FilterComponent')
)
const BookingDeliveryDetails = React.lazy(() =>
	import('../page/module/Report/BookingDelivery/component/BookingDeliveryDetails')
)
const ManageTravelingVsCancelBookingFilter = React.lazy(() =>
	import('../page/module/Report/TravelingVsCancelBooking/component/FilterComponent')
)
const ManageTravelingVsCancelBookingDetails = React.lazy(() =>
	import('../page/module/Report/TravelingVsCancelBooking/component/TravelingVsCancelBookingDetails')
)
const ManageLocationGSTFilter = React.lazy(() =>
	import('../page/module/Report/LocationGST/Component/FilterComponent')
)
const ManageLocationGSTSummary = React.lazy(() =>
	import('../page/module/Report/LocationGST/Component/SummaryDetails')
)
const ManageHOGSTFilter = React.lazy(() =>
	import('../page/module/Report/HOGST/Component/FilterComponent')
)
const ManageHOGSTSummary = React.lazy(() =>
	import('../page/module/Report/HOGST/Component/SummaryDetails')
)
const ManageOutInscanFilter = React.lazy(() =>
	import('../page/module/Report/OutInScanReport/component/FilterComponent')
)
const ManageOutInscanFilterDetails = React.lazy(() =>
	import('../page/module/Report/OutInScanReport/component/OutInscanDetail.js')
)
const ManageInscanAndOutscanActivitiesStatistics = React.lazy(() =>
	import('../page/module/Report/OutInScanReportStatistics/component/FilterComponent.js')
)
const OutInscanDetailStatistics = React.lazy(() =>
	import('../page/module/Report/OutInScanReportStatistics/component/OutInscanDetailStatistics.js')
)
const ManageInvoiceHoToFoFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/HoToFo/Component/FilterComponent.js')
)
const ManageInvoiceHoToFoDetails = React.lazy(() =>
	import('../page/module/Report/Invoice/HoToFo/Component/Details.js')
)
const ManageInvoiceHoToFoSummary = React.lazy(() =>
	import('../page/module/Report/Invoice/HoToFo/Component/Summary.js')
)
const ManageInvoiceFoToHoCreditFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoCredit/Component/FilterComponent')
)
const ManageInvoiceFoToHoCreditDetails = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoCredit/Component/Details')
)
const ManageInvoiceFoToHoReverseFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoReverse/Component/FilterComponent.js')
)
const ManageInvoiceFoToHoReverseDetails = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoReverse/Component/Details.js')
)
const ManageInvoiceFoToHoReverseSummary = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoReverse/Component/Summary.js')
)
const ManageInvoiceFoToHoReverseSummaryLocation = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoReverse/Component/ByLocationSummary.js')
)
const ManageInvoiceFoToHoReverseSummaryCorporate = React.lazy(() =>
	import('../page/module/Report/Invoice/FoToHoReverse/Component/ByCorporateSummary.js')
)
const ManageInvoiceCorporateFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/Corporate/Component/FilterComponent.js')
)
const ManageInvoiceCorporateSummary = React.lazy(() =>
	import('../page/module/Report/Invoice/Corporate/Component/Summary.js')
)
const ManageInvoiceStickerFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/Sticker/Component/FilterComponent.js')
)
const ManageInvoiceStickerDetails = React.lazy(() =>
	import('../page/module/Report/Invoice/Sticker/Component/Details.js')
)
const ManageInvoiceStickerSummary = React.lazy(() =>
	import('../page/module/Report/Invoice/Sticker/Component/Summary.js')
)
const ManageExpenseReportFilter = React.lazy(() =>
	import('../page/module/Report/Expense/component/FilterComponent.js')
)
const ManageExpenseReportSummary = React.lazy(() =>
	import('../page/module/Report/Expense/component/Summary.js')
)
const ManageExpenseReportDetail = React.lazy(() =>
	import('../page/module/Report/Expense/component/Details.js')
)
const ManageExpenseSummaryReportFilter = React.lazy(() =>
	import('../page/module/Report/ExpenseSummary/component/FilterComponent.js')
)
const ManageExpenseSummaryReportSummary = React.lazy(() =>
	import('../page/module/Report/ExpenseSummary/component/Summary.js')
)
const ManageExpenseLedgerFilter = React.lazy(() =>
	import('../page/module/Report/ExpenseLedger/component/FilterComponent.js')
)
const ManageExpenseLedgerDetail = React.lazy(() =>
	import('../page/module/Report/ExpenseLedger/component/Details.js')
)
const ManageInvoiceAccountPartyFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/AccountParty/Component/FilterComponent.js')
)
const ManageInvoiceAccountPartySummary = React.lazy(() =>
	import('../page/module/Report/Invoice/AccountParty/Component/SummaryDetails.js')
)
const ManagePaymentStickerFilter = React.lazy(() =>
	import('../page/module/Report/Payment/Sticker/Component/FilterComponent.js')
)
const ManagePaymentStickerDetails = React.lazy(() =>
	import('../page/module/Report/Payment/Sticker/Component/Details')
)
const ManagePaymentStickerSummary = React.lazy(() =>
	import('../page/module/Report/Payment/Sticker/Component/Summary')
)
const ManagePaymentAccountPartyFilter = React.lazy(() =>
	import('../page/module/Report/Payment/AccountParty/Component/FilterComponent.js')
)
const ManagePaymentAccountPartySummary = React.lazy(() =>
	import('../page/module/Report/Payment/AccountParty/Component/SummaryDetails.js')
)
const ManagePaymentCorporateFilter = React.lazy(() =>
	import('../page/module/Report/Payment/Corporate/Component/FilterComponent.js')
)
const ManagePaymentCorporateSummary = React.lazy(() =>
	import('../page/module/Report/Payment/Corporate/Component/Summary.js')
)
const ManagePaymentHoToFoFilter = React.lazy(() =>
	import('../page/module/Report/Payment/HoToFo/Component/FilterComponent.js')
)
const ManagePaymentHoToFoSummary = React.lazy(() =>
	import('../page/module/Report/Payment/HoToFo/Component/Summary')
)
const ManagePaymentHoToFoDetails = React.lazy(() =>
	import('../page/module/Report/Payment/HoToFo/Component/Details')
)
const ManagePaymentFoToHoReverseFilter = React.lazy(() =>
	import('../page/module/Report/Payment/FoToHoReverse/Component/FilterComponent.js')
)
const ManagePaymentFoToHoReverseSummary = React.lazy(() =>
	import('../page/module/Report/Payment/FoToHoReverse/Component/Summary.js')
)
const ManagePaymentFoToHoReverseDetails = React.lazy(() =>
	import('../page/module/Report/Payment/FoToHoReverse/Component/Details')
)
const ManageInvoiceVsPaymentAccountParty = React.lazy(() =>
	import('../page/module/Report/invoiceVsPayment/AccountParty/Component/FilterComponent')
)
const ManageInvoiceVsPaymentAccountPartyDetails = React.lazy(() =>
	import('../page/module/Report/invoiceVsPayment/AccountParty/Component/DetailsComponent')
)
const ManageInvoiceVsPaymentCorporate = React.lazy(() =>
	import('../page/module/Report/invoiceVsPayment/Corporate/Component/FilterComponent')
)
const ManageInvoiceVsPaymentCorporateDetails = React.lazy(() =>
	import('../page/module/Report/invoiceVsPayment/Corporate/Component/DetailsComponent')
)
const ManageStickerReportFilter = React.lazy(() =>
	import('../page/module/Report/StickerReport/Component/FilterComponent')
)
const ManageStickerReportsummary = React.lazy(() =>
	import('../page/module/Report/StickerReport/Component/SummaryDetails')
)
const ManageCashMobileReportFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/CashMobileReport/Component/FilterComponent.js')
)
const ManageCashMobileReportSummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/CashMobileReport/Component/SummaryDetails.js')
)
const ManageOutstandingHoToFoFilter = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingHoToFo/Component/FilterComponent')
)
const ManageOutstandingHoToFoDetails = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingHoToFo/Component/Details')
)
const ManageOutstandingHoToFoSummaryByHub = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingHoToFo/Component/SummaryByHub')
)
const ManageOutstandingHoToFoSummaryByLocation = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingHoToFo/Component/SummaryByLocation')
)
const ManageOutstandingFoToHoReverseFilter = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingFoToHoReverse/Component/FilterComponent')
)
const ManageOutstandingFoToHoReverseSummaryByHub = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingFoToHoReverse/Component/SummaryByHub')
)
const ManageOutstandingFoToHoReverseSummaryByLocation = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingFoToHoReverse/Component/SummaryByLocation')
)
const ManageOutstandingFoToHoReverseDetails = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingFoToHoReverse/Component/Details')
)
const ManageOutstandingCorporateFilter = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingCorporate/Component/FilterComponent')
)
const ManageOutstandingCorporateDetails = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingCorporate/Component/Details')
)
const ManageOutstandingCorporateSummary = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingCorporate/Component/Summary')
)
const ManageOutstandingAccountPartyFilter = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingAccountParty/Component/FilterComponent')
)
const ManageOutstandingAccountPartyDetails = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingAccountParty/Component/Details')
)
const ManageOutstandingAccountPartySummary = React.lazy(() =>
	import('../page/module/Report/OutstandingReports/OutstandingAccountParty/Component/Summary')
)
const ManageInvoiceVsPaymentHoToFo = React.lazy(() =>
	import('../page/module/Report/invoiceVsPayment/HoToFo/Component/FilterComponent')
)
const ManageInvoiceVsPaymentHoToFoDetails = React.lazy(() =>
	import('../page/module/Report/invoiceVsPayment/HoToFo/Component/DetailsComponent')
)
const ManageDrsStatisticsReportSummary = React.lazy(() =>
	import('../page/module/Report/DrsStatisticsReport/Component/SummaryDetails.js')
)
const ManageDrsStatisticsReportDetail = React.lazy(() =>
	import('../page/module/Report/DrsStatisticsReport/Component/Details.js')
)
const ManageDrsStatisticsReportFilter = React.lazy(() =>
	import('../page/module/Report/DrsStatisticsReport/Component/FilterComponent')
)
const ManageLocationPerformanceByDeliveryReportFilter = React.lazy(() =>
	import('../page/module/Report/LocationPerformanceByDeliveryReport/Component/FilterComponent')
)
const ManageLocationPerformanceByDeliveryReportSummary = React.lazy(() =>
	import('../page/module/Report/LocationPerformanceByDeliveryReport/Component/SummaryDetails')
)
const ManageIncomingLoadWithEwayFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/IncomingLoadWithEway/component/FilterComponent')
)
const ManageIncomingLoadWithEwaySummary = React.lazy(() =>
	import('../page/module/Report/DailyActivities/IncomingLoadWithEway/component/SummaryComponent.js')
)
const ManageInternationalDailyBookingsFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/InternationalDailyBookings/component/FilterComponent.js')
)
const ManageInternationalDailyBookingsDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/InternationalDailyBookings/component/DetailComponent.js')
)
const ManageInternationalBookingsInvoiceFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/InternationalBookingsInvoiceReport/component/FilterComponent.js')
)
const ManageInternationalBookingsInvoiceDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/InternationalBookingsInvoiceReport/component/SummaryDetails.js')
)
const AgencyPaymentFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/AgencyPaymentReport/component/FilterComponent.js')
)
const AgencyPaymentReportDetail = React.lazy(() =>
	import('../page/module/Report/DailyActivities/AgencyPaymentReport/component/Details.js')
)
const ManageWalletTransactionsReportFilter = React.lazy(() =>
	import('../page/module/Report/DailyActivities/WalletTransactions/Component/FilterComponent.js')
)
const ManageWalletTransactionsReportSummary  = React.lazy(() =>
	import('../page/module/Report/DailyActivities/WalletTransactions/Component/SummaryDetails.js')
)
const ManageInternationaInvoiceReportsFilter = React.lazy(() =>
	import('../page/module/Report/Invoice/International/Component/FilterComponent.js')
)
const ManageInternationaInvoiceReportsSummary  = React.lazy(() =>
	import('../page/module/Report/Invoice/International/Component/Summary.js')
)
const ManageFranchiseeInformationFilter  = React.lazy(() =>
	import('../page/module/Report/FranchiseeInformation/component/FilterComponent')
)
const ManageFranchiseeInformationDetail  = React.lazy(() =>
	import('../page/module/Report/FranchiseeInformation/component/DetailComponent')
)


const Reports = React.lazy(() =>
	import('../page/module/Report')
)


/**
 * International booking URLS
 */
const ManageInternationalBooking = React.lazy(() =>
	import('../page/module/International/Booking/')
)
const ManageInternationalBookingAdd = React.lazy(() =>
	import('../page/module/International/Booking/component/AddComponent')
)
const ManageInternationalBookingEdit = React.lazy(() =>
	import('../page/module/International/Booking/component/EditComponent')
)
const ManageTransportationCharge = React.lazy(() =>
	import('../page/module/International/TransportationCharge')
)
const ManageLocationProfitRate= React.lazy(() =>
	import('../page/module/International/LocationProfitRate')
)
const ManageTransportationChargeByLocation = React.lazy(() =>
	import('../page/module/International/TransportationChargeByLocation')
)
const AgencyPayment = React.lazy(() =>
	import('../page/module/International/VendorPayment/component/FilterComponent.js')
)
const AgencyPaymentList = React.lazy(() =>
	import('../page/module/International/VendorPayment/component/ListComponent.js')
)

const AgencyPaymentSummary = React.lazy(() =>
	import('../page/module/International/AgencyPaymentSummary/component/FilterComponent')
)
const CreateAgencyPaymentSummary = React.lazy(() =>
	import('../page/module/International/AgencyPaymentSummary/component/ListComponent')
)
const AgencyPaymentSummaryList = React.lazy(() =>
	import('../page/module/International/AgencyPaymentSummary/component/SummaryList')
)
const AgencyBankAccount = React.lazy(() =>
	import('../page/module/International/AgencyBankAccount')
)

/**
 * Expense URLS
 */
const ManageExpenseHead= React.lazy(() =>
	import('../page/module/Expense/ExpenseHead')
)
const ManageExpense= React.lazy(() =>
	import('../page/module/Expense/ManageExpense')
)

/**
 * Notifications booking URLS
 */
const ViewComplaint = React.lazy(() =>
	import('../page/module/Notifications/ViewComplaints')
)
const IncomingBag = React.lazy(() =>
	import('../page/module/Notifications/IncomingBag')
)
const ReceivedBag = React.lazy(() =>
	import('../page/module/Notifications/ReceivedBag')
)
const NewLocation = React.lazy(() =>
	import('../page/module/Notifications/NewLocation')
)
const WrongSorting = React.lazy(() =>
	import('../page/module/Notifications/WrongSorting')
)
const AuditEntry = React.lazy(() =>
	import('../page/module/Notifications/AuditEntry')
)


/**
 * Location Wallet URLS
 */
const LocationWallet = React.lazy(() =>
	import('../page/module/organization/LocationWallet')
)

export const RouterConfig = [
	{
		title: "Login",
		path: "/",
		component: Login,
		default: "beforeAuth",
	},
	{
		title: "Login",
		path: "/login",
		component: Login,
		default: "beforeAuth",
	},
	{
		title: "Forgot Password",
		path: "/forgot-password",
		component: ForgotPassword,
	},
	{
		title: "Verify Otp",
		path: "/verify-otp/:user_id/:email_id",
		component: VerifyOtp,
	},
	{
		title: "Captcha Verify Otp",
		path: "/captcha-verify-otp/:user_id",
		component: CaptchaVerifyOtp,
	},
	{
		title: "Verify Email",
		path: "/verify-email/:token",
		component: EmailVerify
	},
	{
		title: "Client Email Verify",
		path: "/verify-client-email/:token",
		component: EmailClientVerify
	},
	{
		title: "Reset Password",
		path: "/reset-password/:user_id",
		component: ResetPassword,
	},
	{
		title: "Blank",
		path: "/blank",
		component: Blank,
		auth: true,
	},
	{
		title: "Manage Mobile Tracking",
		path: "/mobile-tracking/:id/:level_id?/:client_id?",
		component: MobileConsignmetnTracking,
		default: "beforeAuth",
	},
	{
		title: "Manage Web Tracking",
		path: "/web-tracking/:id/:level_id?/:client_id?",
		component: MobileConsignmetnTracking,
		default: "beforeAuth",
	},
	{
		title: "Unauthorized",
		path: "/unauthorized",
		component: Unauthorized,
		errorpage: true,
	},
	{
		title: "License expired",
		path: "/license-expired",
		component: LicenseExpired,
		errorpage: true,
	},
	{
		title: "User Profile",
		path: "/change-password",
		component: ChangePassword,
		auth: true,
	},
	{
		title: "Dashboard",
		path: "/dashboard",
		component: Dashboard,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Notes",
		path: "/communication/notes",
		component: ManageNotes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Scrolling",
		path: "/communication/marquees",
		component: ManageScrollingMessages,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Continents",
		path: "/administration/continents",
		component: ManageContinents,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Country",
		path: "/administration/countries",
		component: ManageCountry,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "State",
		path: "/administration/states",
		component: ManageStates,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "City",
		path: "/administration/cities",
		component: ManageCity,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Zones",
		path: "/administration/zones",
		component: ManageZones,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pincodes",
		path: "/administration/pincodes",
		component: ManagePinCode,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pin Code Areas",
		path: "/administration/pincode-areas",
		component: ManagePincodeAreas,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pin Code Tags",
		path: "/administration/pincode-tags",
		component: ManagePincodeTag,
		default: "AuthAuth",
		auth: true,
	},
	{
		title: "Bank",
		path: "/administration/bank",
		component: ManageBank,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Courier Vendors",
		path: "/administration/courier-vendors",
		component: ManageCourierVendors,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Bank Accounts",
		path: "/administration/bank-accounts",
		component: ManageBankAccount,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Delivery Boys",
		path: "/administration/delivery-boyz/users",
		component: ManageDeliveryBoyz,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Staff",
		path: "/administration/staff",
		component: ManageStaff,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Bank Account Alias",
		path: "/organization/bank-account-alias",
		component: ManageBankAccountAlias,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Account Party - Quotes",
		path: "/organization/account-party-quotes",
		component: ManageAccountPartyQuotes,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Corporates",
		path: "/organization/corporates",
		component: ManageCorporates,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Account Parties",
		path: "/organization/account-party",
		component: ManageAccountParty,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Account Party Approval",
		path: "/organization/account-party-approval",
		component: ManageAccountPartyApproval,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Location Approval",
		path: "/organization/location-approval",
		component: ManageLocationApproval,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Franchise Approval",
		path: "/organization/franchisee-approval",
		component: ManageFranchiseApproval,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Corporate - Quotes",
		path: "/organization/corporates-quotes",
		component: ManageCorporateQuotes,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "ManageClientGroup",
		path: "/organization/clients-group",
		component: ManageClientGroup,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Role",
		path: "/administration/roles",
		component: ManageRole,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Users - HO",
		path: "/administration/users",
		component: ManageUsers,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Users - HUB",
		path: "/administration/hub/users",
		component: ManageUsersHub,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Users - Client",
		path: "/administration/client/users",
		component: ManageUsersClient,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Users - Franchisee",
		path: "/administration/franchisee/users",
		component: ManageUsersFranchisee,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Satellite Points",
		path: "/administration/satellite-points",
		component: ManageSatellitePoints,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Payment Modes",
		path: "/administration/payment-modes",
		component: ManagePaymentMode,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Payment Modes",
		path: "/administration/payment-modes",
		component: ManagePaymentMode,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "User Location",
		path: "/administration/location/users",
		component: ManageUsersCenter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Co-Loaders",
		path: "/administration/coloaders",
		component: ManageCoLoaders,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Company Settings",
		path: "/system/configuration",
		component: CompanySettings,
		auth: true,
	},
	{
		title: "Manage Client API - Tracking",
		path: "/system/config/client-api-tracking",
		component: ManageClientApiTracking,
		auth: true,
	},
	{
		title: "Manage Stock",
		path: "/stickers/stock",
		component: ManageStock,
		auth: true,
	},
	{
		title: "Manage Bag Tag Sticker Orders",
		path: "/stickers/sticker-orders-bag-tag",
		component: ManageBagTagStickerOrders,
		auth: true,
	},
	{
		title: "Manage VirtualOrder",
		path: "/stickers/virtual-sticker-orders",
		component: ManageVirtualOrder,
		auth: true,
	},
	{
		title: "Manage Hub Wise Rates",
		path: "/stickers/sticker-rates-hub",
		component: ManageStickerRatesByHub,
		auth: true,
	},
	{
		title: "Manage sticker Tag",
		path: "/stickers/sticker-orders-bag",
		component: ManageBagStickerOrder,
		auth: true,
	},
	{
		title: "Manage sticker packet hub",
		path: "/stickers/sticker-orders-packet-hub",
		component: ManageStickerOrderPacketHub,
		auth: true,
	},
	{
		title: "Manage Allocated Stickers",
		path: "/stickers/allocate-stickers",
		component: ManageAllocatedSticker,
		auth: true,
	},
	{
		title: "Manage Hub Wise Rates",
		path: "/stickers/sticker-rates-location",
		component: ManageStickerRatesByLocation,
		auth: true,
	},
	{
		title: "Manage Stock Transactions",
		path: "/stickers/stock-transaction",
		component: ManageStockTransaction,
		auth: true,
	},
	{
		title: "Manage Sticker Order",
		path: "/stickers/sticker-orders",
		component: ManageStickerOrder,
		auth: true,
	},
	{
		title: "Manage Express Covers",
		path: "/stickers/express-cover-orders",
		component: ManageExpressCoversOrder,
		auth: true,
	},
	{
		title: "Manage Sticker Order - Express",
		path: "/stickers/sticker-orders-express",
		component: ManageExpressStickerOrder,
		auth: true,
	},
	{
		title: "Manage Sticker Order - Packet",
		path: "/stickers/sticker-orders-packet-location",
		component: ManagePacketStickerOrder,
		auth: true,
	},
	{
		title: "Ownership Types",
		path: "/administration/ownership-types",
		component: OwnerShipTypes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Partnership Types",
		path: "/administration/partnership-types",
		component: ParnerShipTypes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Billing Types",
		path: "/administration/billing-types",
		component: BillingTypes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Rate Card Cash",
		path: "/business/rate-card-cash",
		component: ManageRateCashCard,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Rate Card - A/C",
		path: "/business/rate-card-acc",
		component: ManageRateAccountCard,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Rate Card - Corporates",
		path: "/business/rate-card-corporates",
		component: ManageRateCorporatesCard,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Rate Card - Cash - Archived",
		path: "/business/rate-card-cash-archived",
		component: ManageRateCashCardArchived,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Booking Policy - A/C",
		path: "/business/booking-policies-ac",
		component: ManageBookingPolicyAccount,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Rate Cards - A/C - Draft",
		path: "/business/rate-card-draft-account-party",
		component: ManageRateAccountDraftCard,
		defaultl: "AfterAuth",
		auth: true
	},
	{
		title: "Rate Cards - Cash - Draft",
		path: "/business/rate-card-draft-cash",
		component: ManageRateCashDraftCard,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Rate Cards - Corporate - Draft",
		path: "/business/rate-card-draft-corporate",
		component: ManageRateCorporateDraftCard,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking Policy Corporate",
		path: "/business/booking-policies-corporates",
		component: ManageBookingPolicyCorporate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Inscan for Others",
		path: "/bookings/external",
		component: ManageInscanForLocation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Fuel Policies",
		path: "/business/fuel-rate-templates",
		component: ManageFuelPolicies,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Billing Contracts",
		path: "/business/contracts",
		component: ManageContract,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Billing Contracts List",
		path: "/business/contracts/list",
		component: ManageContractList,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Head Offices ",
		path: "/organization/head-offices",
		component: ManageHeadOffice,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Hubs",
		path: "/organization/hubs",
		component: ManageHubs,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage SMS",
		path: "/administration/sms-templates",
		component: ManageSMS,
		auth: true,
	},
	{
		title: "Manage News",
		path: "/communication/news",
		component: ManageNews,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Circulars",
		path: "/communication/circular",
		component: ManageCirculars,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Booking Message",
		path: "/communication/booking-messages",
		component: ManageBookingMessage,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Booking Message List",
		path: "/communication/booking-message-list",
		component: ManageBookingMessageList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Client Message",
		path: "/communication/client-messages",
		component: ManageClientMessage,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Client Message List",
		path: "/communication/client-message-list",
		component: ManageClientMessageList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Circulars",
		path: "/utilities/circulars",
		component: Circulars,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Account Party Search",
		path: "/utilities/account-party-search",
		component: AccountPartySearch,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Greetings",
		path: "/communication/greetings",
		component: ManageGreetings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Greetings Read Receipts",
		path: "/communication/greetings/Receipts/:id",
		component: ManageGreetingsReceipts,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Popup Messages",
		path: "/communication/popup-messages",
		component: ManagePopupMessages,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Popup Messages Read Receipt",
		path: "/communication/popup-msgs-read-receipt/list/:id",
		component: PopupMessagesReadReceiptsList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage A/C Email Pool",
		path: "/communication/acc-email-pool",
		component: ManageAccountEmailPool,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage NEWS Read Receipts",
		path: "/communication/news-read-receipts/list/:id",
		component: ManageNEWSReadReceipts,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage NOTES Read Receipts",
		path: "/communication/notes-read-receipts/list/:id",
		component: NoteReadReceiptsList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage circulars-read-receipt Read Receipts",
		path: "/communication/circulars-read-receipts/list/:id",
		component: CircularsReadReceiptsList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Emails",
		path: "/administration/email-templates",
		component: ManageEmail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Delivery Return Reason",
		path: "/administration/delivery-return-reasons",
		component: ManageDeliveryReturnReason,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Delivery Location Tags",
		path: "/administration/delivery-location-tags",
		component: ManageDeliveryLocationTag,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Document Types",
		path: "/administration/documents-types",
		component: ManageDocumentTypes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Industry Segment",
		path: "/administration/industry-segments",
		component: ManageIndustrySegment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Rate Card Templates",
		path: "/business/rate-card-templates",
		component: ManageRateCard,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "User Sessions",
		path: "/system/user-sessions",
		component: UserSessions,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "ActivityLog",
		path: "/system/activity-log",
		component: ActivityLog,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage FinacialYear",
		path: "/system/financial-years",
		component: ManageFinancialYear,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Levels",
		path: "/system/user-levels",
		component: ManageLevels,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage API Clients",
		path: "/system/api-clients",
		component: ManageApiClient,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage User Devices",
		path: "/administration/user-devices",
		component: UserDevice,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Products",
		path: "/administration/products",
		component: Manageproducts,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Service Areas",
		path: "/business/service-area",
		component: ManageServiceArea,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Rate Types",
		path: "/administration/rate-types",
		component: RateTypes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage DRS Area",
		path: "/administration/drs-area",
		component: ManageDrsArea,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Bank Accounts - HO",
		path: "/organization/bank-accounts-HO",
		component: ManageBankAccountHo,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Bank Accounts - Location",
		path: "/organization/bank-accounts-centers",
		component: ManageBankAccountLocation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Applications",
		path: "/system/applications",
		component: ManageApplication,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage APP Releases",
		path: "/system/app-releases",
		component: ManageAppReleases,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Users - Franchisee",
		path: "/organization/franchisee",
		component: ManageFranchisee,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Terminated Franchisee",
		path: "/organization/franchisee-terminate",
		component: ManageTerminatedFranchisee,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Franchisee Enquiry",
		path: "/organization/franchisee-enquiry",
		component: ManageFrainchaiseeEnquiry,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Piece Rate Templates",
		path: "/business/piece-rate-templates",
		component: ManagePieceRate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Slab Rate Templates",
		path: "/business/slab-rate-templates",
		component: ManageSlabRate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Overhead Exception By Hub",
		path: "/business/overhead-exception-hub",
		component: ManageOverheadExceptionByHub,
		auth: true,
	},
	{
		title: "Manage Overhead Exception By Location",
		path: "/business/overhead-exception-location",
		component: ManageOverheadExceptionByLocation,
		auth: true,
	},
	{
		title: "Manage Apply Overhead By Hub",
		path: "/business/apply-overhead-hub",
		component: ApplyOverheadByHub,
		auth: true,
	},
	{
		title: "Manage Apply Overhead By Location",
		path: "/business/apply-overhead-location",
		component: ApplyOverheadByLocation,
		auth: true,
	},
	{
		title: "Vehicles",
		path: "/administration/Vehicles",
		component: ManageVehicles,
		default: "AuthAuth",
		auth: true,
	},
	{
		title: "Manage Drivers",
		path: "/administration/drivers",
		component: ManageDrivers,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Locations",
		path: "/organization/locations",
		component: ManageLocations,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Traders ",
		path: "/organization/traders",
		component: ManageTraders,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Cash Booking Policy",
		path: "/business/booking-policies-cash",
		component: ManageCashBookingPolicy,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Location Groups",
		path: "/organization/location-groups",
		component: ManageLocationsGroups,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Cash Bookings",
		path: "/bookings/cash-bookings",
		component: ManageCashBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Drs web",
		path: "/bookings/drs",
		component: ManageDrsWeb,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Drs web",
		path: "/bookings/drs-add",
		component: ManageAddDrsWeb,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Edit Drs web",
		path: "/bookings/drs-edit/:id",
		component: ManageEditDrsBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Cash Booking List",
		path: "/bookings/cash-bookings-list",
		component: ManageListCashBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Cash Booking Archive List",
		path: "/bookings/cash-bookings-archive-list/:fy_id",
		component: ManageArchiveListCashBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Cash Booking",
		path: "/bookings/cash-bookings-add",
		component: ManageAddCashBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Account Booking",
		path: "/bookings/account-bookings-add",
		component: ManageAddAccountBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Bulk Cash Booking",
		path: "/bookings/cash-bookings-add-bulk",
		component: ManageBulkAddCashBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Edit Cash Booking",
		path: "/bookings/cash-bookings-edit/:id",
		component: ManageEditCashBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Edit Account Booking",
		path: "/bookings/account-bookings-edit/:id",
		component: ManageEditAccountBooking,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Account Bookings",
		path: "/bookings/account-bookings",
		component: ManageAccountBooking,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Account Booking List",
		path: "/bookings/account-bookings-list",
		component: ManageListAccountBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Account Booking Archive List",
		path: "/bookings/account-bookings-archive-list/:fy_id",
		component: ManageArchiveListAccountBookings,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Packet Entry",
		path: "/bookings/packets",
		component: ManagePacketEntry,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Bag Entry",
		path: "/bookings/bags",
		component: ManageBagEntry,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Return-To-Origin Entries",
		path: "/bookings/return-to-origin",
		component: ManageReturnToOriginEntry,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Return-To-Origin",
		path: "/bookings/return-to-origin/for-me",
		component: ManageReturnToOrigin,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Non Serviceable Entries",
		path: "/bookings/non-serviceable-entries",
		component: ManageNonServiceableEntry,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Outscan Entry",
		path: "/bookings/outward",
		component: ManageOutwardEntry,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Inscan Entry",
		path: "/bookings/inward",
		component: ManageInwardEntry,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Inscan Client Booking API",
		path: "/bookings/inscan-client-booking-api",
		component: ManageInscanClientBookingAPI,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Eway Part B",
		path: "/bookings/eway/transit",
		component: ManageEwayTransit,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Eway First Mile",
		path: "/bookings/eway/transit-first-mile",
		component: ManageEwayFirstMile,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Outscan Pending for Eway",
		path: "/bookings/eway/outscan-pending",
		component: ManageEwayOutScanPending,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Outscan Pending for Eway - Summary",
		path: "/bookings/eway/outscan-pending-summary",
		component: ManageEwayOutScanPendingSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Complaint",
		path:  "/bookings/manage-complaint",
		component: ManageComplaint,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Sticker Vendors",
		path: "/administration/stickervendors",
		component: ManageStickerVendors,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Wrong Sorting Entry",
		path: "/bookings/wrong-sorting",
		component: ManageWrongSorting,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Wrong Sorting For Me",
		path: "/bookings/wrong-sorting/for-me",
		component: wrongSortingForMe,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "ManageLostShipment",
		path: "/bookings/lost-shipments",
		component: ManageLostShipment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Lost Shipment For Me",
		path: "/bookings/lost-shipments/for-me",
		component: LostShimentForMe,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Audit Weight",
		path: "/bookings/audit-weight",
		component: ManageAuditWeight,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Audit Weight",
		path: "/bookings/forword-bookings",
		component: ManageForwardBooking,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Account Booking",
		path: "/bookings/forword-bookings-add",
		component: ManageAddForwardBooking,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Training Videos",
		path: "/help/training-videos",
		component: ManageTrainingVideos,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Account Booking Send Email",
		path: "/utilities/ac-booking-send-email",
		component: ManageAccountBookingSendEmail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Location Search",
		path: "/utilities/location-search",
		component: ManageLocationSearch,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pincode Search",
		path: "/utilities/pincode-search",
		component: ManagePincodeSearch,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Hub Search",
		path: "/utilities/hub-search",
		component: ManageHubSearch,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Transfer Booking Entry",
		path: "/bookings/account-booking-transfer",
		component: ManageTransferEntry,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Update Booking Rates",
		path: "/bookings/update-booking-rate",
		component: ManageUpdateBookingRate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Update Piece Rate Per Quotation",
		path: "/bookings/update-piece-rate-per-quotation",
		component: ManageUpdatePieceRatePerQuotation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Downloads",
		path: "/help/downloads",
		component: ManageDownloads,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Client Report",
		path: "/reports/client-report",
		component: ManageClientReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "New Client Report",
		path: "/reports/new-client-report",
		component: NewClientReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "New Client Report",
		path: "/reports/new-client-report-details",
		component: NewClientReportDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking Type",
		path: "/reports/client-report-details",
		component: ManageClientReportDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking Type",
		path: "/reports/booking-type",
		component: ManageBookingTypeFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking Type",
		path: "/report/booking-type-details",
		component: ManageBookingTypeDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking Type",
		path: "/report/booking-type-summary",
		component: ManageBookingTypeSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking Type",
		path: "/report/booking-type-basic-summary",
		component: ManageBookingTypeBasicSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "City Wise Booking",
		path: "/reports/city-wise-bookings",
		component: ManageCityWiseBookingFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "City Wise Booking",
		path: "/reports/city-wise-bookings-summary",
		component: ManageCityWiseBookingSum,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "City Wise Booking",
		path: "/reports/city-wise-bookings-detail",
		component: ManageCityWiseBookingDetail,
		default: "AfterAuth",
		auth: true
	},
	
	{
		title: "Location Collection",
		path: "/reports/location-collection",
		component: ManageLocationCollectionFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Location Collection - Detail",
		path: "/reports/location-collection-detail",
		component: ManageLocationCollectionDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Location Collection - Summary",
		path: "/reports/location-collection-summary",
		component: ManageLocationCollectionSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Forwarding Booking",
		path: "/reports/forwarding-booking",
		component: ManageForwordingBookingFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Forwarding Booking - Detail",
		path: "/reports/forwarding-booking-detail",
		component: ManageForwardingBookingDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Forwarding Booking - Summary",
		path: "/reports/forwarding-booking-summary",
		component: ManageForwardingBookingSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Corporate Cilent Booking",
		path: "/reports/client-booking",
		component: ManageClientBooking,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Outward Manifest",
		path: "/reports/outward-manifest-details",
		component: ManageOutwardManifestFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Outward Manifest Details",
		path: "/reports/outward-details",
		component: ManageOutwardManifestDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Insurance",
		path: "/reports/booking-insurance",
		component: ManageInsurance,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Inscan Report",
		path: "/reports/inscan-report",
		component: ManageInscanReport,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Inscan Report - Details",
		path: "/reports/inscan-details",
		component: ManageInscanDetailsReport,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Inscan Report - Summary",
		path: "/reports/inscan-summary",
		component: ManageInscanSummaryReport,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Insurance Details",
		path: "/reports/booking-insurance-details",
		component: ManageInsuranceDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Insurance Summary",
		path: "/reports/booking-insurance-summary",
		component: ManageInsuranceSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Corporate Cilent Booking Details",
		path: "/reports/corporate-client-booking-detail",
		component: ManageClientBookingDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking vs Delivery",
		path: "/reports/booking-delivery",
		component: ManageBookingDeliveryFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking VS Delivery",
		path: "/reports/booking-vs-delivery",
		component: ManageBookingDeliveryFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Booking VS Delivery - Detail",
		path: "/reports/booking-vs-delivery/detail",
		component: BookingDeliveryDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Relive Cancel Bookings",
		path: "/reports/traveling-vs-cancel-bookings",
		component: ManageTravelingVsCancelBookingFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Traveling vs Cancel Booking - Detail",
		path: "/reports/traveling-vs-cancel-bookings/detail",
		component: ManageTravelingVsCancelBookingDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manage Unregistered Packet Filter",
		path: "/reports/unregistered-packets",
		component: ManageUnregisteredPacketFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Unregistered Packets - Summary",
		path: "/reports/unregistered-packets/summary",
		component: ManageUnregisteredPacketSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Unregistered Packets - Detail",
		path: "/reports/unregistered-packets/detail",
		component: ManageUnregisteredPacketDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Unregistered Bookings Filter",
		path: "/reports/unregistered-bookings",
		component: ManageUnregisteredBookingsFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Unregistered Bookings - Summary",
		path: "/reports/unregistered-bookings/summary",
		component: ManageUnregisteredBookingsSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Unregistered Bookings - Detail",
		path: "/reports/unregistered-bookings/detail",
		component: ManageUnregisteredBookingsDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Inscan & Outscan Activities",
		path: "/reports/inscan-outscan-activities",
		component: ManageInscanAndOutscanActivitiesFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Inscan & Outscan Activities - summary",
		path: "/report/inscan-outscan-activities/byhublocation",
		component: SummaryByHubLocation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Inscan & Outscan Activities - summary",
		path: "/report/inscan-outscan-activities/bydate",
		component: SummaryByDate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outscan Vs Missing Inscan",
		path: "/reports/outscan-vs-missing-inscan",
		component: ManageOutscanvsMissingInscanFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outscan Vs Missing Inscan - Summary",
		path: "/reports/outscan-vs-missing-inscan/summary",
		component: ManageOutscanvsMissingInscanSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outscan Vs Missing Inscan - Detail",
		path: "/reports/outscan-vs-missing-inscan/detail",
		component: ManageOutscanvsMissingInscanDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Unregistered Bag Filter",
		path: "/reports/unregistered-bags",
		component: ManageUnregisteredBagFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Unregistered Bag - Summary",
		path: "/reports/unregistered-bags/summary",
		component: ManageUnregisteredBagSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Unregistered Bag - Detail",
		path: "/reports/unregistered-bags/detail",
		component: ManageUnregisteredBagDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Daily Bookings Filter",
		path: "/reports/daily-bookings",
		component: ManageDailyBookingsFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Daily Bookings - Summary",
		path: "/reports/daily-bookings/summary",
		component: ManageDailyBookingsSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Daily Bookings - Detail",
		path: "/reports/daily-bookings/detail",
		component: ManageDailyBookingsDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Load for Hub Filter",
		path: "/reports/incoming-load-for-hub",
		component: ManageHubIncomingLoadFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Load for Hub - Summary",
		path: "/reports/incoming-load-for-hub/summary",
		component: ManageHubIncomingLoadSummary,
		default: "AfterAuth",
		auth: true,
	},

	{
		title: "E-way Statistics Filter",
		path: "/reports/incoming-load-with-eway",
		component: ManageIncomingLoadWithEwayFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "E-way Statistics - Summary",
		path: "/reports/incoming-load-with-eway/summary",
		component: ManageIncomingLoadWithEwaySummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Load for Location Filter",
		path: "/reports/incoming-load-for-location",
		component: ManageLocationIncomingLoadFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Load for Location - Summary",
		path: "/reports/incoming-load-for-location/summary",
		component: ManageLocationIncomingLoadSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Load for Hub Filter",
		path: "/reports/pending-load-for-hub",
		component: ManageHubPendingLoadFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Load for Hub - Summary",
		path: "/reports/pending-load-for-hub/summary",
		component: ManageHubPendingLoadSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending DRS Load Filter",
		path: "/reports/pending-drs-load",
		component: ManageDrsPendingLoadFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending DRS Load - Detail",
		path: "/reports/pending-drs-load/summary",
		component: ManageDrsPendingLoadSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending DRS Load - Summary",
		path: "/reports/pending-drs-load/summary-short",
		component: ManageDrsPendingLoadShortSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Pending Booking Load Filter",
		path: "/reports/pending-booking-load",
		component: ManageBookingPendingLoadFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Deposits",
		path: "/account/deposit",
		component: ManageDeposits,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Booking Load - Summary",
		path: "/reports/pending-booking-load/summary",
		component: ManageBookingPendingLoadSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Franchisee Login Details",
		path: "/reports/fr-login-details",
		component: ManageFranchiseeLogin,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Location Login Details",
		path: "/reports/location-login-details",
		component: ManageLocationLogin,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Location - Service Pincode Area Details",
		path: "/reports/location-service-pincode-area-details",
		component: ManagelocationServiceAreaLogin,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Hub wise Inscan/Outscan",
		path: "/reports/inscan-outscan-by-hubs/summary",
		component: ManageHubWiseInscanOutscanSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Hub wise Inscan/Outscan Report",
		path: "/reports/inscan-outscan-by-hubs",
		component: ManageHubWiseInscanOutscanFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "A/C Party Quotation Copy",
		path: "/bookings/copy-account-party-quotation",
		component: ManageCopyAccountPartyQuotation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "On Demand Report",
		path: "/administration/on-demand-report",
		component: ManageOnDemandReport,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Location Creation Information",
		path: "/reports/location-creation-information",
		component: ManageLocationCreationInformationFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Location Creation Information List",
		path: "/reports/location-creation-information-list",
		component: ManageLocationCreationInformationListReport,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Billing",
		path: "/monthly/billing",
		component: ManageBilling,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Billing Generate",
		path: "/monthly/billing-generate",
		component: ManagebillingGenerate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Bills",
		path: "/monthly/bills",
		component: ManagebillingSearch,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Re-Generate/Cancelled Bills",
		path: "/monthly/cancelled-bills",
		component: ManagebillingCancelled,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Cancelled Bills",
		path: "/monthly/cancelled-bills-history",
		component: ManagebillingCancelledHistory,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Estimation",
		path: "/monthly/estimation",
		component: ManageEstimation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Estimation Generate",
		path: "/monthly/estimation-generate",
		component: ManageEstimationGenerate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Bills",
		path: "/monthly/estimation-bills",
		component: ManageEstimationSearch,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Cancelled Bills",
		path: "/monthly/estimation-cancelled-bills",
		component: ManageEstimationCancelled,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Invoices",
		path: "/corporate-billing/billing",
		component: ManageCorporatesBilling,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Invoices Generate",
		path: "/corporate-billing/billing-generate",
		component: ManageCorporatesBillingGenerate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Invoices Bills",
		path: "/corporate-billing/bills",
		component: ManageCorporatesBillingSearch,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Cancelled Bills",
		path: "/corporate/cancelled-bills",
		component: ManageCorporatesBillingCancelled,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Invoices Job",
		path: "/corporate-billing/invoices-job",
		component: ManageCorporateInvoiceJob,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Invoices Job Generate",
		path: "/corporate-billing/invoices-job-generate",
		component: ManageCorporateInvoiceJobGenerate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Invoices Job Bills",
		path: "/corporate-billing/invoices-job-bills",
		component: ManageCorporateInvoiceJobBills,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Billing",
		path: "/ho-activity/jobs",
		component: ManageHOBilling,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Create Job BY Hub",
		path: "/ho-activity/jobs-by-hub",
		component: ManageHOBillingCreateJobBYHub,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Create Job BY Location Group",
		path: "/ho-activity/jobs-by-location-group",
		component: ManageHOBillingJobBYLocationGroup,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Billing Jobs",
		path: "/ho-activity/ho-billing-jobs",
		component: ManageHOBillingjobs,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Billing Requests",
		path: "/ho-activity/ho-billing-requests",
		component: ManageHOBillingRequests,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Billing Not Requests",
		path: "/ho-activity/ho-billing-not-requests",
		component: ManageHOBillingNotRequests,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Billing Bill Due Detail",
		path: "/ho-activity/ho-billing-not-requests-bill-detail",
		component: ManageHOBillingDueDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Billing",
		path: "/ho-activity/ho-pending-billing",
		component: ManageHOPendingBilling,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Invoices",
		path: "/ho-activity/invoices",
		component: ManageHOBillingInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Invoices",
		path: "/ho-activity/performa-invoices",
		component: ManagePerformaInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Invoices",
		path: "/ho-activity/pending-invoices",
		component: ManagePendingInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Invoices",
		path: "/ho-activity/tax-invoices",
		component: ManageTaxInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Invoices Without GST",
		path: "/ho-activity/invoices-without-gst",
		component: ManageInvoicesWithoutGST,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Payment",
		path: "/ho-activity/payments",
		component: ManageHOPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Payment Receipt",
		path: "/ho-activity/new-payment",
		component: ManageHONewInvoicesPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Payment History",
		path: "/ho-activity/payment-history",
		component: ManageHOPaymentHistory,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outstanding By Account Party",
		path: "/ho-activity/payment-outstanding",
		component: ManageHOPaymentOutstanding,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Settlement",
		path: "/ho-activity/settlement",
		component: ManageHOSettlement,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Auto HO Settlement",
		path: "/ho-activity/auto-settlement",
		component: ManageAutoHOSettlement,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Settlement Receipt",
		path: "/ho-activity/new-settlement",
		component: ManageHONewSettlementPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "HO Settlement History",
		path: "/ho-activity/settlement-history",
		component: ManageHOSettlementHistory,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Ho Edit Booking",
		path: "/bookings/ho-booking",
		component: ManageHoEditBooking,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Search",
		path: "/bookings/incoming-search",
		component: IncomingSearchFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Search - Summary",
		path: "/bookings/incoming-search-summary",
		component: IncomingSearchSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outgoing Search",
		path: "/bookings/outgoing-search",
		component: OutgoingSearchFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outgoing Search - Summary",
		path: "/bookings/outgoing-search-summary",
		component: OutgoingSearchSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "International Bookings",
		path: "/international/bookings",
		component: ManageInternationalBooking,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "International Bookings Add",
		path: "/international/bookings/add",
		component: ManageInternationalBookingAdd,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Edit International Bookings",
		path: "/international/bookings/edit/:id",
		component: ManageInternationalBookingEdit,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Transportation Charge",
		path: "/international/transportation-charge",
		component: ManageTransportationCharge,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Transportation Charge By Location",
		path: "/international/location-transportation-charge",
		component: ManageTransportationChargeByLocation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Update Location Profit Rate",
		path: "/international/center-charge",
		component: ManageLocationProfitRate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Vendor Payment",
		path: "/international/int-agency-payment",
		component: AgencyPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Vendor Payment List",
		path: "/international/int-agency-payment-list",
		component: AgencyPaymentList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Agency Payment Summary",
		path: "/international/int-agency-payment-summary",
		component: AgencyPaymentSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Create Agency Payment Summary List",
		path: "/international/int-agency-payment-summary-create",
		component: CreateAgencyPaymentSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Agency Payment Summary List",
		path: "/international/int-agency-payment-summary-list",
		component: AgencyPaymentSummaryList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Agency Bank Account",
		path: "/international/int-agency-bank-accounts",
		component: AgencyBankAccount,
		default: "AfterAuth",
		auth: true,
	},	
	{
		title: "Corporates Invoices",
		path: "/corporate-billing/invoices",
		component: ManageCorporatesInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Client Invoices",
		path: "/monthly/bill-invoices",
		component: ManageBillingInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Estimation Invoices",
		path: "/monthly/estimation-invoices",
		component: ManageEstimationInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Invoices Payment",
		path: "/monthly/bill-payment",
		component: ManageInvoicesPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Payment Receipt",
		path: "/monthly/new-payment",
		component: ManageNewInvoicesPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Payment History",
		path: "/monthly/payment-history",
		component: ManagePaymentHistory,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outstanding By Account Party",
		path: "/monthly/payment-outstanding",
		component: ManagePaymentOutstanding,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Estimation Payment",
		path: "/monthly/estimation-payment",
		component: ManageEstimationPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporate Payment",
		path: "/corporate-billing/payment",
		component: ManageCorporatePayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Payment Receipt",
		path: "/corporate-billing/corporate-payment/:invId?",
		component: ManageCorporateNewPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporate Payment History",
		path: "/monthly/corporate-payment-history",
		component: ManageCorporatePaymentHistory,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Outstanding By Account Party",
		path: "/monthly/corporate-payment-outstanding",
		component: ManageCorporatePaymentOutstanding,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Settlement",
		path: "/monthly/payment-pending-settlement",
		component: ManageCorporatePendingSettlement,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Weight Machine",
		path: "/administration/weight-machine",
		component: WeightMachine,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Configure - Locking for Pending DRS Closure",
		path: "/lock/pending-drs",
		component: ManageConfigPendingDrs,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Configure - Locking for Pending DRS Closure - By Location",
		path: "/lock/pending-drs-by-location",
		component: ManageConfigPendingDrsByLocation,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Configure - Locking for Missing Consignments",
		path: "/lock/missing-consignment",
		component: ManageConfigMissingConNo,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Configure - Locking for Pending DRS Closure - By Location",
		path: "/lock/missing-consignment-by-location",
		component: ManageConfigMissingConNoByLocation,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Configure - Locking for Zero DRS",
		path: "/lock/zero-drs",
		component: ManageConfigZeroDrs,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Manual Lock",
		path: "/locks/manual-lock",
		component: ManageConfigManualLock,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Lock Booking By Pincode",
		path: "/locks/pincode-booking",
		component: ManageBookingByPincode,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Bookings Lock",
		path: "/lock/bookings",
		component: ManageBookingLock,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Tracking Lock",
		path: "/lock/tracking",
		component: ManageTrackingLock,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Location GST",
		path: "/reports/location-gst",
		component: ManageLocationGSTFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Location GST - Summary",
		path: "/reports/location-gst-summary",
		component: ManageLocationGSTSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "HO GST",
		path: "/reports/ho-gst-report",
		component: ManageHOGSTFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "HO GST - Summary",
		path: "/reports/ho-gst-report-summary",
		component: ManageHOGSTSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outscan Inscan Report",
		path: "/reports/outscan-inscan",
		component: ManageOutInscanFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outscan Inscan Report - Details",
		path: "/reports/outscan-inscan-details",
		component: ManageOutInscanFilterDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outscan Inscan Report statistics",
		path: "/reports/outscan-inscan-statistics",
		component: ManageInscanAndOutscanActivitiesStatistics,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outscan Inscan Report - Details - statistics",
		path: "/reports/outscan-inscan-details-statistics",
		component: OutInscanDetailStatistics,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Reverse Invoices",
		path: "/ho-activity/reverse-invoices",
		component: ManageReverseBillingInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Invoices",
		path: "/ho-activity/performa-reverse-invoices",
		component: ManageReversePerformaInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Invoices",
		path: "/ho-activity/pending-reverse-invoices",
		component: ManageReversePendingInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Invoices",
		path: "/ho-activity/tax-reverse-invoices",
		component: ManageReverseTaxInvoices,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Invoices Without GST",
		path: "/ho-activity/reverse-invoices-without-gst",
		component: ManageReverseInvoicesWithoutGST,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Payment",
		path: "/ho-activity/reverse-payments",
		component: ManageReversePayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse New Payment Receipt",
		path: "/ho-activity/reverse-new-payment",
		component: ManageReverseNewInvoicesPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Payment History",
		path: "/ho-activity/reverse-payment-history",
		component: ManageReversePaymentHistory,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reverse Outstanding By Account Party",
		path: "/ho-activity/reverse-payment-outstanding",
		component: ManageReversePaymentOutstanding,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Invoice HO To FO",
		path: "/reports/invoice-ho-to-fo",
		component: ManageInvoiceHoToFoFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice HO To FO",
		path: "/report/invoice-ho-to-fo-details",
		component: ManageInvoiceHoToFoDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice HO To FO",
		path: "/report/invoice-ho-to-fo-summary",
		component: ManageInvoiceHoToFoSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO To HO Credit",
		path: "/reports/invoice-ho-to-fo-credit",
		component: ManageInvoiceFoToHoCreditFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO To HO Credit",
		path: "/reports/invoice-ho-to-fo-credit-details",
		component: ManageInvoiceFoToHoCreditDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO TO HO",
		path: "/reports/invoice-fo-to-ho",
		component: ManageInvoiceFoToHoReverseFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO TO HO",
		path: "/report/invoice-fo-to-ho-details",
		component: ManageInvoiceFoToHoReverseDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO TO HO",
		path: "/report/invoice-fo-to-ho-summary",
		component: ManageInvoiceFoToHoReverseSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO TO HO",
		path: "/report/invoice-fo-to-ho-summary-by-location",
		component: ManageInvoiceFoToHoReverseSummaryLocation,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice FO TO HO",
		path: "/report/invoice-fo-to-ho-summary-by-corporate",
		component: ManageInvoiceFoToHoReverseSummaryCorporate,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Corporate",
		path: "/reports/invoice-corporate",
		component: ManageInvoiceCorporateFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Corporate",
		path: "/report/invoice-corporate-details",
		component: ManageInvoiceCorporateSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Sticker",
		path: "/reports/invoice-sticker",
		component: ManageInvoiceStickerFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Sticker",
		path: "/report/invoice-sticker-details",
		component: ManageInvoiceStickerDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Sticker",
		path: "/report/invoice-sticker-summary",
		component: ManageInvoiceStickerSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Report",
		path: "/reports/expense-report",
		component: ManageExpenseReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Report",
		path: "/reports/expense-report-summary",
		component: ManageExpenseReportSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Report",
		path: "/reports/expense-report-detail",
		component: ManageExpenseReportDetail,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Summary Report",
		path: "/reports/summary/expense-report",
		component: ManageExpenseSummaryReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Summary Report",
		path: "/reports/summary/expense-report-summary",
		component: ManageExpenseSummaryReportSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Ledger",
		path: "/reports/expense-ledger",
		component: ManageExpenseLedgerFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Expense Ledger",
		path: "/reports/expense-ledger-detail",
		component: ManageExpenseLedgerDetail,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Sticker",
		path: "/reports/invoice-account-party",
		component: ManageInvoiceAccountPartyFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice Sticker",
		path: "/reports/invoice-account-party-details",
		component: ManageInvoiceAccountPartySummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Sticker",
		path: "/reports/payment-sticker",
		component: ManagePaymentStickerFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Sticker",
		path: "/report/payment-sticker-details",
		component: ManagePaymentStickerDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Sticker",
		path: "/report/payment-sticker-summary",
		component: ManagePaymentStickerSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Account Party",
		path: "/reports/payment-account-party",
		component: ManagePaymentAccountPartyFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Account Party",
		path: "/reports/payment-account-party-details",
		component: ManagePaymentAccountPartySummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Corporate",
		path: "/reports/payment-corporate",
		component: ManagePaymentCorporateFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment Corporate",
		path: "/report/payment-corporate-details",
		component: ManagePaymentCorporateSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment HO To FO",
		path: "/reports/payment-ho-to-fo",
		component: ManagePaymentHoToFoFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment HO To FO",
		path: "/reports/payment-ho-to-fo-summary",
		component: ManagePaymentHoToFoSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment HO To FO",
		path: "/reports/payment-ho-to-fo-details",
		component: ManagePaymentHoToFoDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment FO TO HO",
		path: "/reports/payment-fo-to-ho",
		component: ManagePaymentFoToHoReverseFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment HO To FO",
		path: "/reports/payment-fo-to-ho-summary",
		component: ManagePaymentFoToHoReverseSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Payment FO To HO",
		path: "/reports/payment-fo-to-ho-details",
		component: ManagePaymentFoToHoReverseDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Forward Booking Ho Edit",
		path: "/bookings/forwardlist",
		component: ManageHoEditForwardBooking,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Audit Shipment",
		path: "/bookings/audit-shipment",
		component: ManageAuditShipment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Invoice vs Payment A/C Party",
		path: "/reports/invoice-payment-account-party",
		component: ManageInvoiceVsPaymentAccountParty,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice vs Payment A/C Party Details",
		path: "/reports/invoice-payment-account-party-details",
		component: ManageInvoiceVsPaymentAccountPartyDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice vs Payment Corporate",
		path: "/reports/invoice-payment-corporate",
		component: ManageInvoiceVsPaymentCorporate,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice vs Payment Corporate Details",
		path: "/reports/invoice-payment-corporate-details",
		component: ManageInvoiceVsPaymentCorporateDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice vs Payment HO To FO",
		path: "/reports/invoice-payment-ho-to-fo",
		component: ManageInvoiceVsPaymentHoToFo,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Invoice vs Payment HO To FO Details",
		path: "/reports/invoice-payment-ho-to-fo-details",
		component: ManageInvoiceVsPaymentHoToFoDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Sticker Report",
		path: "/reports/sticker-details",
		component: ManageStickerReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Sticker Report - Summary",
		path: "/reports/sticker-details-summary",
		component: ManageStickerReportsummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Cash Booking Mobile Number Report",
		path: "/reports/cash-mobile-report",
		component: ManageCashMobileReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Cash Booking Mobile Number Report - Summary",
		path: "/reports/cash-mobile-report-summary",
		component: ManageCashMobileReportSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding HO To FO",
		path: "/reports/outstanding-ho-to-fo",
		component: ManageOutstandingHoToFoFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding HO To FO",
		path: "/report/outstanding-ho-to-fo-details",
		component: ManageOutstandingHoToFoDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding HO To FO",
		path: "/report/outstanding-ho-to-fo-summary",
		component: ManageOutstandingHoToFoSummaryByHub,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding HO To FO",
		path: "/report/outstanding-ho-to-fo-summary-location",
		component: ManageOutstandingHoToFoSummaryByLocation,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding FO to HO",
		path: "/reports/outstanding-fo-to-ho",
		component: ManageOutstandingFoToHoReverseFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding FO to HO",
		path: "/reports/outstanding-fo-to-ho-details",
		component: ManageOutstandingFoToHoReverseDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding FO to HO",
		path: "/reports/outstanding-fo-to-ho-summary",
		component: ManageOutstandingFoToHoReverseSummaryByHub,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding FO to HO",
		path: "/reports/outstanding-fo-to-ho-summary-location",
		component: ManageOutstandingFoToHoReverseSummaryByLocation,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding Corporate",
		path: "/reports/outstanding-corporate",
		component: ManageOutstandingCorporateFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding Corporate",
		path: "/reports/outstanding-corporate-details",
		component: ManageOutstandingCorporateDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding Corporate",
		path: "/reports/outstanding-corporate-summary",
		component: ManageOutstandingCorporateSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding Account Party",
		path: "/reports/outstanding-account-party",
		component: ManageOutstandingAccountPartyFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding Account Party",
		path: "/reports/outstanding-account-party-details",
		component: ManageOutstandingAccountPartyDetails,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Outstanding Account Party",
		path: "/reports/outstanding-account-party-summary",
		component: ManageOutstandingAccountPartySummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "DRS Statistics Report",
		path: "/reports/drs-statistics-report",
		component: ManageDrsStatisticsReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "DRS Statistics Report - Summary",
		path: "/reports/drs-statistics-report-summary",
		component: ManageDrsStatisticsReportSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "DRS Statistics Report - Detail",
		path: "/reports/drs-statistics-report-detail",
		component: ManageDrsStatisticsReportDetail,
		default: "AfterAuth",
		auth: true
	},
    {
		title: "Location Performance By DRS Report",
		path: "/reports/location-performance-report",
		component: ManageLocationPerformanceByDeliveryReportFilter,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "Location Performance By DRS Report",
		path: "/reports/location-performance-report-summary",
		component: ManageLocationPerformanceByDeliveryReportSummary,
		default: "AfterAuth",
		auth: true
	},
	{
		title: "ICICI Data Sync",
		path: "/bookings/icici-data-sync",
		component: ManageICICIDataSync,
		default: "AfterAuth",
		auth: true
	},	
	{
		title: "Pending Bills",
		path: "/monthly/pending-bills",
		component: PendingBillsSearchFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Bills - Detail",
		path: "/monthly/pending-bills-detail",
		component: PendingBillsSearchDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Pending Bills - Summary",
		path: "/monthly/pending-bills-summary",
		component: PendingBillsSearchSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Pending Bills",
		path: "/corporate-billing/pending-bills",
		component: CorporatesPendingBillsFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Corporates Pending Bills - Summary",
		path: "/corporate-billing/pending-bills-summary",
		component: CorporatesPendingBillsSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Software Charge Entry",
		path: "/ho-activity/software-charge",
		component: SoftwareCharge,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "International Daily Bookings Filter",
		path: "/international/daily-int-bookings",
		component: ManageInternationalDailyBookingsFilter,
		default: "AfterAuth",
		auth: true,
	},	
	{
		title: "International Daily Bookings - Detail",
		path: "/international/daily-int-bookings/detail",
		component: ManageInternationalDailyBookingsDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "International Bookings Invoice Filter",
		path: "/international/int-invoice-report",
		component: ManageInternationalBookingsInvoiceFilter,
		default: "AfterAuth",
		auth: true,
	},	
	{
		title: "International Bookings Invoice - Detail",
		path: "/international/int-invoice-report/detail",
		component: ManageInternationalBookingsInvoiceDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Agency Payment Filter",
		path: "/international/int-payment-report",
		component: AgencyPaymentFilter,
		default: "AfterAuth",
		auth: true,
	},	
	{
		title: "Agency Payment - Detail",
		path: "/international/int-payment-report/detail",
		component: AgencyPaymentReportDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Expense Head",
		path: "/expense/expense-head",
		component: ManageExpenseHead,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Staff Attendance",
		path: '/bookings/staff-attendance',
		component: ManageStaffAttendance,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Staff Salary",
		path: '/bookings/staff-salary',
		component: ManageSalaryProcessing,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "International Training Videos",
		path: "/international/training-videos",
		component: ManageTrainingVideos,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Expense ",
		path: "/expense/expense-manage",
		component: ManageExpense,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Expense ",
		path: "/notifications/complain",
		component: ViewComplaint,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Incoming Bag ",
		path: "/notifications/incoming-bags",
		component: IncomingBag,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Received Bag ",
		path: "/notifications/received-bags",
		component: ReceivedBag,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Wrong Sorting ",
		path: "/notifications/wrong-sorting",
		component: WrongSorting,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "New Location ",
		path: "/notifications/new-location",
		component: NewLocation,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Audit Entry ",
		path: "/notifications/audit-entry",
		component: AuditEntry,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Booking Edit Ho Approve",
		path: "/bookings/update-bookings",
		component: BookingEditHoApprove,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Disturb Pincodes",
		path: "/administration/disturb-pincodes",
		component: DisturbPincodes,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Location Wallet",
		path: "/organization/location-wallet",
		component: LocationWallet,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Eway Add",
		path: "/eway/eway-update",
		component: ManageEwayUpdate,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Process List",
		path: "/system/process",
		component: ManageProcessList,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "GenerateAccountPartyLabel",
		path: "/monthly/account-party/generate-label",
		component: ManageGenerateAccountPartyLabel,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Reports",
		path: "/reports",
		component: Reports,
		auth: true,
	},	
	{
		title: "Wallet Transactions Filter",
		path: "/reports/wallet-transactions",
		component: ManageWalletTransactionsReportFilter,
		default: "AfterAuth",
		auth: true,
	},	
	{
		title: "Wallet Transactions - Detail",
		path: "/reports/wallet-transactions/detail",
		component: ManageWalletTransactionsReportSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "International Invoice Filter",
		path: "/reports/invoice-international",
		component: ManageInternationaInvoiceReportsFilter,
		default: "AfterAuth",
		auth: true,
	},	
	{
		title: "International Invoice - Summary",
		path: "/reports/invoice-international/summary",
		component: ManageInternationaInvoiceReportsSummary,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Franchisee Information",
		path: "/reports/franchisee-information",
		component: ManageFranchiseeInformationFilter,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Franchisee Information- Detail",
		path: "/reports/franchisee-information-detail",
		component: ManageFranchiseeInformationDetail,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Manage Advanced Payment",
		path: "/organization/location-adavanced-payment",
		component: ManageAdvancedPayment,
		default: "AfterAuth",
		auth: true,
	},
	{
		title: "Page404",
		path: "/",
		component: Page404,
		exact: "false",
		errorpage: true,
	},
];
